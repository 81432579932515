import styled from "styled-components";

export const DashboardWrapper = styled.div`
  .headerContainer {
    justify-content: center;
  }
  .date {
    max-width: 140px;
  }
  .summary-row {
    margin-top: 50px;
    padding: 10px 0;
  }
  .table-row:nth-last-child(2) {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .extract-report {
    font-weight: 600;
    cursor: pointer;
    text-transform: none;
    color: black;
    border: 1px solid rgba(0, 0, 0, 0.2);
    height: 32px;
    &:hover {
      outline: none;
    }
  }
`;
