import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SearchWrapper } from "./searchablestyle";

const SearchableDropdown = (props) => {
  const [data, _data] = useState(props.dropdownData);
  const [filteredData, _filteredData] = useState([]);
  const [value, _value] = useState("");

  useEffect(() => {
    data?.forEach((element) => {
      _filteredData((olddata) => [
        ...olddata,
        {
          label: element.fullname,
          value: element.id,
        },
      ]);
    });
  }, []);

  useEffect(() => {
    if (props.val) {
      props.setChangeValue(props.val);
      _value(data.find((o) => o.id == props.val).fullname);
    } else {
      _value("");
    }
  }, [props.val]);

  return (
    <SearchWrapper>
      <Autocomplete
        key={props.name}
        disableClearable
        className={props.className}
        options={filteredData}
        sx={{ width: 250 }}
        value={value}
        freeSolo
        forcePopupIcon={true}
        renderOption={(props, option) => (
          <li {...props} key={props.name + "" + option.value}>
            {option.label}
          </li>
        )}
        onChange={(event, newValue) => {
          props.setChangeValue(newValue.value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={props.placeholder}
            onChange={(e) => {
              if (e.target.value == "") {
                props.setChangeValue("");
              }
            }}
            id={props.id ? props.id : "searchableField"}
          />
        )}
      />
    </SearchWrapper>
  );
};

export default SearchableDropdown;
