import React, { Fragment, useEffect, useMemo, useState } from "react";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import API from "../../../../../api/axios";
import { APP_URLS } from "../../../../../api/url";
import edit from "../../../../../assets/Images/image/edit.png";
import close from "../../../../../assets/Images/icons/Cancel.svg";
import save from "../../../../../assets/Images/icons/Save.svg";
import profilePic from "../../../../../assets/Images/icons/profile-pic.png";
import Pagination from "../../../../../assets/SharedComponents/Pagination";
import NoRecord from "../../../../../assets/SharedComponents/Table/NoRecord";
import * as Constant from "../../../../../config/constant";
import moment from "moment";
import SearchKey from "../../../../../assets/SharedComponents/Search/SearchKey";
import { confirmAlert } from "react-confirm-alert";
import {
  FaDownArrow,
  FaUpArrow,
  FaFilterIcon,
} from "../../../../../assets/Images/icon";
import { Autocomplete, Checkbox, TextField, Tooltip } from "@mui/material";
import { ThreeDots } from "react-loader-spinner";
import RecordCount from "../../../../../assets/SharedComponents/RecordCount/RecordCount";
import Popup from "./Popup";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import StaticFilter from "../../../../../assets/SharedComponents/Filters/StaticFilter";
import Sorting from "../../../../../assets/SharedComponents/Sorting/Sorting";
import { generateQueryString } from "../../../../../core/utils";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AccountCreationDatesList = Object.keys(Constant.AccountCreationDates).map(
  (key) => ({ name: key, value: Constant.AccountCreationDates[key] })
);
const verifiedProfileStatusList = Object.keys(
  Constant.verifiedProfileStatus
).map((key) => ({ name: key, value: Constant.verifiedProfileStatus[key] }));
const ProfileCompletionStatusList = Object.keys(
  Constant.ProfileCompletionStatus
).map((key) => ({ name: key, value: Constant.ProfileCompletionStatus[key] }));

const AdminPanel = ({ usersAccess, roleAccess }) => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const [searchParams, setSearchParams] = useSearchParams();

  const [data, _data] = useState([]);

  const [searchkeyword, _searchkeyword] = useState(
    query.get("keyword") !== null ? query.get("keyword") : ""
  );
  const [countValue, _countValue] = useState(
    query.get("per_page") !== null ? query.get("per_page") : ""
  );
  const [active, _active] = useState(
    query.get("status") !== null ? query.get("status") : ""
  );
  const [sort, _sort] = useState(
    query.get("sort") !== null ? query.get("sort") : "first_name"
  );
  const [filterRole, _filterRole] = useState(
    query.get("role_id") !== null ? query.get("role_id") : ""
  );
  const [profile, _profile] = useState(
    query.get("profile") !== null ? query.get("profile") : ""
  );
  const [accountDate, _accountDate] = useState(
    query.get("accountDate") !== null ? query.get("accountDate") : ""
  );
  const [accountDateFrom, _accountDateFrom] = useState(
    query.get("accountDateFrom") !== null ? query.get("accountDateFrom") : ""
  );
  const [accountDateTo, _accountDateTo] = useState(
    query.get("accountDateTo") !== null ? query.get("accountDateTo") : ""
  );
  const [trashed, _trashed] = useState(
    searchParams.get("trashed") !== null
      ? searchParams.get("trashed") === "false"
        ? false
        : true
      : true
  );

  const [countryList, _countryList] = useState([]);
  const [selectedCountry, _selectedCountry] = useState([]);
  const [selectedfilter, _selectedfilter] = useState([]);

  const [loaded, _loaded] = useState(false);

  const [adminRoles, _adminRoles] = useState({});

  const [countries, _countries] = useState(
    JSON.parse(localStorage.getItem("countries"))
  );

  const [page, setPage] = useState(
    query.get("page") !== null ? parseInt(query.get("page")) : 1
  );
  const [totalPage, _totalPage] = useState(0);
  const [total, _total] = useState(0);

  const [adminID, _adminID] = useState("");
  const [roleID, _roleID] = useState("");
  const [loading, _loading] = useState(false);

  const [filters, _filters] = useState(false);

  const [popup, _popup] = useState("");

  const permission = usersAccess?.permissions.find((o) => o.slug === "add");
  const rolePermission = roleAccess?.permissions.find(
    (o) => o.slug === "update"
  );

  useEffect(() => {
    getAdmin(); //fetching admin users listing
  }, [
    page,
    searchkeyword,
    filterRole,
    active,
    sort,
    countValue,
    profile,
    accountDate,
    selectedfilter,
    trashed,
  ]);

  useEffect(() => {
    if (
      accountDate === Constant.AccountCreationDatesType.CUSTOM_TO_AND_FROM &&
      accountDateFrom !== "" &&
      accountDateTo !== ""
    ) {
      getAdmin();
    }
  }, [accountDate, accountDateFrom, accountDateTo]);

  useEffect(() => {
    getAdminRoles(); //fetching roles for admin
  }, []);

  useEffect(() => {
    let values = [];
    countries?.forEach((element) => {
      values.push({
        title: element.fullname,
        value: element.id,
      });
    });
    _countryList(values);
  }, [countries]);

  useEffect(() => {
    if (query.get("selectedCountry") !== null && countryList.length > 0) {
      let arr = [];
      let selectedvalues = query.get("selectedCountry").split(",");
      selectedvalues.forEach((element, index) => {
        if (index !== selectedvalues.length - 1) {
          arr.push(countryList.find((o) => o.value == element));
        }
      });
      _selectedCountry(arr);
      _selectedfilter(arr);
    }
  }, [countryList]);

  //Managing params for admin panel
  function getApiParams(str) {
    let urlParams = {
      type: Constant.USER_TYPE.ADMIN_PANEL,
    };

    if (page > 1) urlParams["page"] = page;
    if (countValue) urlParams["per_page"] = countValue;
    if (filterRole) urlParams["filter[role_id]"] = filterRole;
    if (active !== undefined) urlParams["filter[active]"] = active;
    if (sort) urlParams["sort"] = sort;
    if (searchkeyword) urlParams["keyword"] = searchkeyword;
    if (profile) urlParams["filter[user_profile]"] = profile;
    if (
      accountDate &&
      accountDate !== Constant.AccountCreationDatesType.CUSTOM_TO_AND_FROM
    ) {
      urlParams["filter[created_at]"] = accountDate;
    }
    if (
      accountDate === Constant.AccountCreationDatesType.CUSTOM_TO_AND_FROM &&
      accountDateFrom &&
      accountDateTo
    ) {
      urlParams["filter[created_at]"] = accountDate;
      urlParams["create_account_from_date"] = accountDateFrom;
      urlParams["create_account_to_date"] = accountDateTo;
    }
    if (str) urlParams["filter[country_id]"] = str;
    urlParams["with_trashed"] = trashed ? 1 : 0;

    return new URLSearchParams(urlParams).toString();
  }

  //fetching admin users listing
  const getAdmin = () => {
    let str = "";
    if (selectedfilter.length > 0) {
      selectedfilter.forEach((element) => {
        str += element.value + ",";
      });
    }
    const queryParams = getApiParams(str);
    _loaded(true);
    API.get(`${APP_URLS.LIST_USERS}?${queryParams}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.data);
          _totalPage(resp.data.last_page);
          _loaded(false);
          _total(resp.data.total);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loaded(false);
        }
      })
      .catch(function (error) {
        _loaded(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map(
              (error, index) => (error_message = resp.data.errors[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  //fetching roles for admin
  const getAdminRoles = () => {
    API.get(APP_URLS.LIST_ADMIN_ROLES + `?limited_data=true`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          let data = {};
          resp.data.items.forEach((element) => {
            data[`${element.id}_${element.role_name}`] = element.role_name;
          });
          _adminRoles(data);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data?.data).map(
              (error, index) => (error_message = resp.data.data[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const removeType = (index) => {
    confirmAlert({
      title: "Confirm to remove",
      message: `Are you sure you want to revoke access?`,
      closeOnEscape: true,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            API.delete(
              APP_URLS.REMOVE_ADMIN_ACCESS + `/delete_user_role/${index}`
            )
              .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                  getAdmin();
                  toast.success("Admin access has been revoked successfully.", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                  {
                    Object.keys(resp.data.data).map(
                      (error, index) =>
                        (error_message = resp.data.data[error][0])
                    );
                  }
                } else if (resp.data.data?.error !== undefined) {
                  error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                  error_message = resp.data.error;
                } else {
                  error_message = resp.data.message;
                }
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-custom-class-name",
    });
  };

  //manage onChange for search
  const handleSearchChange = (func, searchValue) => {
    func(searchValue);
    if (
      (func === _countValue && searchValue !== countValue) ||
      (func === _searchkeyword && searchValue !== searchkeyword)
    ) {
      if (searchValue !== "") {
        _totalPage(0);
        setPage(1);
      }
    }
  };

  //managing onChnage for admin roles
  const changeAdminRole = (role, userID) => {
    _loading(true);
    const data = {};
    data["role_id"] = role;
    API.post(
      APP_URLS.CHANGE_ADMIN_ROLE + `/update_user_role/${userID}?_method=PATCH`,
      data
    )
      .then((res) => {
        _loading(false);
        const resp = res.data;
        if (resp.success === true) {
          toast.success("Admin Role has been updated successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          _roleID("");
          _adminID("");
          getAdmin();
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        _loading(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data?.data).map(
              (error, index) => (error_message = resp.data.data[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  //Reset filters
  const clearFilter = () => {
    _filters(true);
    handleSearchChange(_countValue, "");
    handleSearchChange(_searchkeyword, "");
    _active("");
    _filterRole("");
    _profile("");
    _accountDate("");
    _accountDateFrom("");
    _accountDateTo("");
    _selectedCountry([]);
    _selectedfilter([]);
    _trashed(true);
    _sort("first_name");
    if (
      searchkeyword !== "" ||
      countValue !== "" ||
      sort !== "first_name" ||
      active !== "" ||
      filterRole !== "" ||
      profile !== "" ||
      accountDate !== "" ||
      accountDateFrom !== "" ||
      accountDateTo !== "" ||
      selectedfilter.length > 0 ||
      trashed !== true
    ) {
      _totalPage(0);
      setPage(1);
    }
    for (var value of query.keys()) {
      const param = searchParams.get(value);
      if (param) {
        searchParams.delete(value);
        setSearchParams(searchParams);
      }
    }
  };

  const setcountryString = () => {
    let str = "";
    if (selectedfilter.length > 0) {
      selectedfilter.forEach((element) => {
        str += element.value + ",";
      });
    }
    return str;
  };

  const handleAccountDate = (e) => {
    setPage(1);
    _totalPage(0);
    _accountDate(e.target.value);
    _accountDateFrom("");
    _accountDateTo("");
  };

  //mamanging sorting values
  const setsorting = (val) => {
    _sort(val);
    if (sort !== val) {
      setPage(1);
      _totalPage(0);
    }
  };

  //managing params url for detail member page
  function createParamsObj() {
    let params = {
      tab: Constant.USER_TYPE_TEXT.ADMIN_PANEL,
    };

    if (active !== "") params["status"] = active;
    if (filterRole !== "") params["role_id"] = filterRole;
    if (searchkeyword !== "") params["keyword"] = searchkeyword;
    if (profile !== "") params["profile"] = profile;
    if (accountDate !== "") params["accountDate"] = accountDate;
    if (accountDateFrom !== "") params["accountDateFrom"] = accountDateFrom;
    if (accountDateTo !== "") params["accountDateTo"] = accountDateTo;
    if (selectedCountry.length > 0)
      params["selectedCountry"] = setcountryString();
    if (countValue !== "") params["per_page"] = countValue;
    if (sort !== "first_name") params["sort"] = sort;
    if (trashed !== true) params["trashed"] = trashed;
    if (page !== 1) params["page"] = page;

    return params;
  }

  //Manage parameters and url for detail member.
  function getDetailMemberUrl(id) {
    const paramsObj = createParamsObj();
    const queryString = generateQueryString(paramsObj);
    return `/detail-member/${id}?${queryString}`;
  }

  return (
    <>
      <Popup popup={popup} _popup={_popup} />
      <div className="d-flex justify-content-between align-items-center mt-2 invite-admin">
        <div className="invitation mb-2">
          {permission ? (
            <NavLink to="/invite-admin">
              <button className="btn">+ Invite Admin</button>
            </NavLink>
          ) : (
            ""
          )}
        </div>
        <div className="d-flex gap-2 select-info mb-2 justify-content-end">
          <StaticFilter
            placeholder="Account Creation Date"
            data={AccountCreationDatesList}
            handleChange={handleAccountDate}
            value={accountDate}
          />
          {accountDate ===
          Constant.AccountCreationDatesType.CUSTOM_TO_AND_FROM ? (
            <>
              <input
                type="date"
                name="accountDateFrom"
                className="filterBox"
                value={accountDateFrom}
                onChange={(e) => _accountDateFrom(e.target.value)}
              />
              <input
                type="date"
                name="accountDateTo"
                className="filterBox"
                value={accountDateTo}
                onChange={(e) => _accountDateTo(e.target.value)}
              />
            </>
          ) : (
            ""
          )}
          {countryList.length > 0 && (
            <Autocomplete
              multiple
              id="tags-standard"
              className="filterBox"
              options={countryList}
              disableCloseOnSelect
              disableClearable
              value={selectedCountry}
              freeSolo
              getOptionLabel={(option) => option.title}
              onChange={(event, newValue) => {
                _selectedCountry(newValue);
              }}
              onBlur={() => {
                setPage(1);
                _totalPage(0);
                _selectedfilter(selectedCountry);
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.title}
                </li>
              )}
              style={{ width: 500 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={
                    selectedCountry.length > 0
                      ? `${selectedCountry.length} ${
                          selectedCountry.length === 1 ? "Country" : "Countries"
                        } Selected`
                      : "Select Countries"
                  }
                />
              )}
            />
          )}
          <StaticFilter
            placeholder="Profile"
            data={ProfileCompletionStatusList}
            handleChange={(e) => {
              setPage(1);
              _totalPage(0);
              _profile(e.target.value);
            }}
            value={profile}
          />
          <StaticFilter
            placeholder="Status"
            data={verifiedProfileStatusList}
            handleChange={(e) => {
              setPage(1);
              _totalPage(0);
              _active(e.target.value);
            }}
            value={active}
          />
          <select
            name="role"
            id="role"
            className="filterBox"
            onChange={(e) => {
              setPage(1);
              _totalPage(0);
              _filterRole(e.target.value);
            }}
            value={filterRole}
          >
            <option hidden>Role</option>
            <option value="">Role</option>
            {Object.keys(adminRoles).map((role, index) => {
              console.log({ role });
              return (
                <option value={role} key={index}>
                  {(
                    adminRoles[role].charAt(0).toUpperCase() +
                    adminRoles[role].slice(1)
                  ).replace("_", " ")}
                </option>
              );
            })}
          </select>
          <SearchKey
            onSearchClick={(search) =>
              handleSearchChange(_searchkeyword, search)
            }
            val={searchkeyword}
            filters={filters}
            _filters={_filters}
            placeholder="Search by Name and Email"
          />
          <RecordCount
            onSearchClick={(search) => handleSearchChange(_countValue, search)}
            id="pageCount"
            val={countValue}
            filters={filters}
            _filters={_filters}
          />
          <div className="d-flex align-items-center">
            <Tooltip title="Clear Filter" placement="bottom">
              <FaFilterIcon
                className="filter-icon"
                role="button"
                onClick={clearFilter}
              />
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between info-wrap">
        <div className="justify-content-end mt-1 me-2">
          <span className="text-start">
            Total Records : <b>{total}</b>
          </span>
        </div>
        <div className="mt-1">
          <input
            type="checkbox"
            checked={!trashed}
            onChange={() => {
              _totalPage(0);
              setPage(1);
              _trashed(!trashed);
            }}
          />
          <span className="ms-2">Hide Deleted Data</span>
        </div>
      </div>

      <div className="tableContainer">
        <table className="auctionTable">
          <thead className="tableHead">
            <tr className="table-row">
              <th className="w200 time-start d-flex">
                <div className="d-flex flex-column text-start">
                  <span className="d-flex flex-row  align-items-center">
                    Name
                    <Sorting
                      sortType="first_name"
                      sort={sort}
                      handleSort={setsorting}
                    />
                  </span>
                  Email
                  <br /> Nickname
                </div>
              </th>
              <th className="time-start d-flex flex-column align-items-center">
                <div className="d-flex">
                  <span>Start Time</span>
                  <Sorting
                    sortType="first_name"
                    sort={sort}
                    handleSort={setsorting}
                  />
                </div>
                <div className="d-flex">
                  <span>End Time</span>
                  <Sorting
                    sortType="deleted_at"
                    sort={sort}
                    handleSort={setsorting}
                  />
                </div>
              </th>
              <th>Status</th>
              <th>Invitation Status</th>
              <th className="w24">Role</th>
              <th className="w150">Last Access</th>
              <th className="w24">Access</th>
            </tr>
          </thead>
          {loaded ? (
            <div className="d-flex justify-content-center">
              <ThreeDots
                height="150"
                width="150"
                radius="9"
                color="#637df4"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          ) : (
            <tbody>
              {data.length > 0 ? (
                data.map((adminUser, index) => {
                  let name = "";
                  if (adminUser.first_name || adminUser.last_name) {
                    name = `${
                      adminUser.first_name !== null ? adminUser.first_name : ""
                    } ${
                      adminUser.last_name !== null ? adminUser.last_name : ""
                    }`;
                  } else {
                    name = adminUser.pseudo;
                  }
                  const detailMemberUrl = getDetailMemberUrl(adminUser.id);
                  return (
                    <tr
                      className={
                        (adminUser.deleted_at
                          ? "deletedRow"
                          : adminUser.latest_role_user_sub.deleted_at
                          ? " revokedRow"
                          : "") + " table-row"
                      }
                      key={index}
                    >
                      <td className="w200 d-flex align-items-center">
                        <div className="profile-flag">
                          <div className="profileImage">
                            <img
                              src={
                                adminUser.profile_picture_url
                                  ? adminUser.profile_picture_url
                                  : profilePic
                              }
                            />
                          </div>
                          {adminUser.limited_address !== null ? (
                            <img
                              className="countryflag"
                              src={adminUser.limited_address.flag_thumbnail}
                              title={adminUser.limited_address.country}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        {adminUser.deleted_at === null ? (
                          <div className="userDetails d-flex flex-column align-items-start">
                            <NavLink
                              to={detailMemberUrl}
                              className="backOfficelink"
                            >
                              <span
                                className="name-text"
                                title={
                                  name !== null
                                    ? name
                                        .replace("_", " ")
                                        .charAt(0)
                                        .toUpperCase() +
                                      name.replace("_", " ").slice(1)
                                    : "-"
                                }
                              >
                                {name !== null
                                  ? name
                                      .replace("_", " ")
                                      .charAt(0)
                                      .toUpperCase() +
                                    name.replace("_", " ").slice(1)
                                  : "-"}
                              </span>
                            </NavLink>
                            <span
                              className="name-text "
                              title={adminUser.email ? adminUser.email : ""}
                            >
                              {adminUser.email ? adminUser.email : "-"}
                            </span>
                            <span>{adminUser?.pseudo || "-"}</span>
                          </div>
                        ) : (
                          <div className="userDetails">
                            <span
                              className="disabled name-text"
                              title={
                                name !== null
                                  ? name
                                      .replace("_", " ")
                                      .charAt(0)
                                      .toUpperCase() +
                                    name.replace("_", " ").slice(1)
                                  : "-"
                              }
                            >
                              {name !== null
                                ? name
                                    .replace("_", " ")
                                    .charAt(0)
                                    .toUpperCase() +
                                  name.replace("_", " ").slice(1)
                                : "-"}
                            </span>
                            <span
                              className="name-text disabled"
                              title={adminUser.email ? adminUser.email : ""}
                            >
                              {adminUser.email ? adminUser.email : "-"}
                            </span>
                          </div>
                        )}
                      </td>
                      <td>
                        <span className="d-block">
                          {moment(adminUser.created_at)
                            .tz("Europe/Amsterdam")
                            .format("DD-MM-YYYY HH:mm")}
                        </span>
                        <span className="d-block">
                          {adminUser.deleted_at !== null
                            ? moment(adminUser.deleted_at)
                                .tz("Europe/Amsterdam")
                                .format("DD-MM-YYYY HH:mm")
                            : "-"}
                        </span>
                      </td>
                      <td>
                        <span
                          className={
                            adminUser.active === 1
                              ? "active-status"
                              : "end-status"
                          }
                        >
                          {Constant.USER_ACTIVE_STATUS[adminUser.active]}
                        </span>
                      </td>
                      <td
                        className={
                          adminUser.latest_role_user_sub.status.name.en ===
                          "accepted"
                            ? "active-status"
                            : adminUser.latest_role_user_sub.status.name.en ===
                              "in_progress"
                            ? "progress-status"
                            : "end-status"
                        }
                      >
                        {(
                          adminUser.latest_role_user_sub.status.name.en
                            .charAt(0)
                            .toUpperCase() +
                          adminUser.latest_role_user_sub.status.name.en.slice(1)
                        ).replace("_", " ")}
                      </td>
                      <td className="w24">
                        {adminUser.id !== adminID ? (
                          <>
                            {adminUser.latest_role_user_sub.role.role_name
                              .replace("_", " ")
                              .charAt(0)
                              .toUpperCase() +
                              adminUser.latest_role_user_sub.role.role_name
                                .replace("_", " ")
                                .slice(1)}
                            {adminUser.is_owner === false &&
                            adminUser.deleted_at === null &&
                            adminUser.latest_role_user_sub.status.name.en !==
                              "refused" &&
                            adminUser.latest_role_user_sub.deleted_at ===
                              null &&
                            rolePermission ? (
                              <img
                                src={edit}
                                alt=""
                                className="mx-2"
                                role="button"
                                onClick={() => _adminID(adminUser.id)}
                              />
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          <div className="roleEdit d-flex">
                            <select
                              name="adminRole"
                              className={
                                (loading ? "disabled" : "") + " form-control"
                              }
                              onChange={(e) =>
                                _roleID(e.target.value.split("_")[0])
                              }
                              disabled={loading}
                              style={{ height: "auto" }}
                            >
                              <option hidden>Role</option>
                              {Object.keys(adminRoles).map((role, index) => {
                                return (
                                  <option value={role} key={index}>
                                    {(
                                      adminRoles[role].charAt(0).toUpperCase() +
                                      adminRoles[role].slice(1)
                                    ).replace("_", " ")}
                                  </option>
                                );
                              })}
                            </select>
                            <button
                              disabled={loading}
                              className={
                                (loading ? "disabled" : "") + " action"
                              }
                              onClick={() => changeAdminRole(roleID, adminID)}
                            >
                              <img src={save} alt="" role="button" />
                            </button>
                            <button
                              disabled={loading}
                              className={
                                (loading ? "disabled" : "") + " action"
                              }
                              onClick={() => {
                                _roleID("");
                                _adminID("");
                              }}
                            >
                              <img src={close} alt="" role="button" />
                            </button>
                          </div>
                        )}
                      </td>
                      <td className="w150">
                        {adminUser.last_accessed_at
                          ? moment(adminUser.last_accessed_at)
                              .tz("Europe/Amsterdam")
                              .format("DD-MM-YYYY HH:mm")
                          : "-"}
                      </td>
                      <td className="w24">
                        {adminUser.is_owner !== true ? (
                          adminUser.latest_role_user_sub.deleted_at === null ? (
                            <div>
                              {adminUser.deleted_at === null ? (
                                <button
                                  className="reject"
                                  onClick={() => removeType(adminUser.id)}
                                >
                                  REVOKE
                                </button>
                              ) : (
                                ""
                              )}
                              <button
                                className="accept"
                                onClick={() => _popup(adminUser.id)}
                              >
                                HISTORY
                              </button>
                            </div>
                          ) : (
                            <div>
                              <button className="revoked disabled" disabled>
                                REVOKED
                              </button>
                              <button
                                className="accept"
                                onClick={() => _popup(adminUser.id)}
                              >
                                HISTORY
                              </button>
                            </div>
                          )
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NoRecord />
              )}
            </tbody>
          )}
        </table>
      </div>

      <div>
        {totalPage > 1 ? (
          <Pagination
            totalPages={totalPage}
            key={totalPage}
            page={page}
            onPageClick={(page) => setPage(page + 1)}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default AdminPanel;
