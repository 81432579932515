import { Button, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import SearchKey from "../../../../assets/SharedComponents/Search/SearchKey";
import FiltersNomad from "./filtersNomad";
import attach from "../../../../assets/Images/icons/svgIcon/Attach.svg";
import error from "../../../../assets/Images/icons/svgIcon/Error.svg";
import {
  FaDownArrow,
  FaFilterIcon,
  FaUpArrow,
} from "../../../../assets/Images/icon";
import Header from "./Header";
import faHide from "../../../../assets/Images/icons/svgIcon/FaHide.svg";
import API from "../../../../api/axios";
import { APP_URLS } from "../../../../api/url";
import { toast } from "react-toastify";
import RecordCount from "../../../../assets/SharedComponents/RecordCount/RecordCount";
import NoRecord from "../../../../assets/SharedComponents/Table/NoRecord";
import Pagination from "../../../../assets/SharedComponents/Pagination";
import download from "downloadjs";
import axios from "axios";
import moment from "moment";
import Popup from "./Popup";
import * as Constant from "../../../../config/constant";
import StaticFilter from "../../../../assets/SharedComponents/Filters/StaticFilter";
import {
  constructQueryStringFromIds,
  downloadFileError,
  formatCurrency,
} from "../../../../core/utils";
import ExtractReport from "../../../../assets/SharedComponents/ExtractReport/ExtractReport";

const paymentStatusOptionsList = Object.keys(Constant.paymentStatusOptions).map(
  (key) => ({ name: key, value: Constant.paymentStatusOptions[key] })
);

const Nomadtable = ({
  tabView,
  setTabView,
  showFilters,
  _showFilters,
  nomads,
  taxRates,
  _action,
  action,
}) => {
  const [data, _data] = useState([]);
  const [nomadSalesCount, _nomadSalesCount] = useState("");
  const [totalAmount, _totalAmount] = useState("");
  const [count, _count] = useState(0);
  const [searchkeyword, _searchkeyword] = useState("");
  const [sort, _sort] = useState("nomad_sales_count");
  const [status, _status] = useState("");
  const [trashed, _trashed] = useState(true);

  const [filters, _filters] = useState(false);
  const [loaded, _loaded] = useState(false);

  const [page, setPage] = useState(1);
  const [totalPage, _totalPage] = useState(0);
  const [countValue, _countValue] = useState("");

  //SideBarFilters
  const [nomadName, _nomadName] = useState("");
  const [commissionVAT, _commissionVAT] = useState("");
  const [country, _country] = useState("");
  const [startDate, _startDate] = useState("");
  const [endDate, _endDate] = useState("");

  const [popup, _popup] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [allInvoices, _allInvoices] = useState(false);

  useEffect(() => {
    getNomads();
  }, [
    page,
    countValue,
    status,
    searchkeyword,
    nomadName,
    startDate,
    endDate,
    country,
    commissionVAT,
    sort,
    trashed,
  ]);

  const getNomads = () => {
    const apiParams = getRestUrl();
    _loaded(true);
    API.get(`${APP_URLS.LIST_NOMAD_INVOICE}?${apiParams}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.nomad_invoice.data);
          _totalPage(resp.data.nomad_invoice.last_page);
          _nomadSalesCount(resp.data.nomad_sales_count.nomadSalesCount);
          _totalAmount(resp.data.total_amount);
          _count(resp.data.nomad_invoice.total);
          _loaded(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loaded(false);
        }
      })
      .catch(function (error) {
        _loaded(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map(
              (error, index) => (error_message = resp.data.errors[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  function getRestUrl() {
    let urlParams = {};

    if (page) urlParams["page"] = page;
    if (countValue) urlParams["per_page"] = countValue;
    if (searchkeyword) urlParams["keyword"] = searchkeyword;
    if (status) urlParams["filter[status]"] = status;
    if (nomadName) urlParams["filter[nomad_user_id]"] = nomadName;
    if (startDate)
      urlParams["filter[nomad_invoice_plan_start_date]"] = startDate;
    if (endDate) urlParams["filter[nomad_invoice_plan_end_date]"] = endDate;
    if (country) urlParams["filter[country_id]"] = country;
    if (commissionVAT) urlParams["filter[commission_vat_id]"] = commissionVAT;
    if (sort) urlParams["sort"] = sort;
    if (trashed !== undefined) urlParams["with_trashed"] = trashed ? 1 : 0;

    const restUrl = new URLSearchParams(urlParams);
    return restUrl.toString();
  }

  const getAttachment = (url) => {
    API.get(url, { responseType: "blob" })
      .then((res) => {
        const resp = res.data;
        if (resp !== null) {
          toast.success("Downloading. Please wait", {
            position: toast.POSITION.TOP_RIGHT,
          });
          const content = res.headers["content-type"];
          download(resp, `invoice.pdf`, content);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        if (error.response) {
          toast.error("Something went wrong", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  const handleSearchChange = (func, searchValue) => {
    func(searchValue);
    if (searchValue !== "") {
      _totalPage(0);
      setPage(1);
    }
  };

  const clearFilter = () => {
    _filters(true);
    _status("");
    _sort("nomad_sales_count");
    _trashed(true);
    handleSearchChange(_countValue, "");
    handleSearchChange(_searchkeyword, "");
    if (searchkeyword !== "" || status !== "" || trashed !== true) {
      _totalPage(0);
      setPage(1);
    }
  };

  //to download excel extract report for this page
  const handleExtractReport = () => {
    const apiurl = getRestUrl();
    API.get(`${APP_URLS.LIST_NOMAD_INVOICE}/download_excel_file?${apiurl}`, {
      responseType: "blob",
    })
      .then((res) => {
        const resp = res.data;
        if (resp !== null) {
          toast.success("Downloading. Please wait", {
            position: toast.POSITION.TOP_RIGHT,
          });
          const content = res.headers["content-type"];
          const currentDate = moment().format("YYYY-MM-DD HH-mm-ss");
          const fileName = `${currentDate}_nomad_invoices_report.xlsx`;
          download(resp, fileName, content);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(async function (error) {
        let error_message = await downloadFileError(error);
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  //to download zip file for invoices
  const handleDownloadInvoiceZip = () => {
    if (selectedIds.length === 0) {
      toast.error("Please select at least one invoice to download.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    //constructing params for multiple invoices based on id's
    const selectedIdsParams = constructQueryStringFromIds(selectedIds, "id");

    API.get(
      `${APP_URLS.LIST_NOMAD_INVOICE}/download_zip_file?${selectedIdsParams}`
    )
      .then((res) => {
        const resp = res.data;
        if (resp.success) {
          toast.success(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map(
              (error, index) => (error_message = resp.data.errors[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message || "Something went wrong";
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  //handles particular selecting checkbox for invoice download in zip format
  const handleCheckboxChange = (id) => {
    setSelectedIds((prevIds) =>
      prevIds.includes(id)
        ? prevIds.filter((item) => item !== id)
        : [...prevIds, id]
    );
  };

  //handles selecting all checkbox for invoice download in zip format
  const handleSelectAll = () => {
    if (allInvoices) {
      setSelectedIds([]);
    } else {
      setSelectedIds(data.map((item) => item.id));
    }
    _allInvoices(!allInvoices);
  };

  return (
    <div className="auctionMainContainer">
      <Popup popup={popup} _popup={_popup} />
      <div className="row mt-4 position-relative">
        <img
          src={faHide}
          alt="filters"
          className={(showFilters ? "open" : "close") + " filterbtn"}
          onClick={() => _showFilters(!showFilters)}
        />
        <div
          className={
            (showFilters === true ? "show" : "hide") + " col-md-3 col-lg-2 mb-5"
          }
        >
          <FiltersNomad
            nomads={nomads}
            taxRates={taxRates}
            nomadName={nomadName}
            _nomadName={_nomadName}
            commissionVAT={commissionVAT}
            _commissionVAT={_commissionVAT}
            country={country}
            _country={_country}
            startDate={startDate}
            _startDate={_startDate}
            endDate={endDate}
            _endDate={_endDate}
            setPage={setPage}
            _totalPage={_totalPage}
          />
        </div>
        <div
          className={
            showFilters === true ? "col-md-9 col-lg-10" : "col-md-12 col-lg-12"
          }
        >
          <Header
            tabView={tabView}
            setTabView={setTabView}
            action={action}
            _action={_action}
            handleDownloadInvoiceZip={handleDownloadInvoiceZip}
          />
          <div className="col-md-12 mt-2">
            <div className="d-flex align-items-center justify-content-end flex-wrap">
              <div className="justify-content-end mb-2 d-flex gap-2 mb-2 flex-wrap align-items-center">
                <select
                  name="status"
                  id="status"
                  className="filterBox"
                  value={status}
                  onChange={(e) => {
                    _status(e.target.value);
                    _totalPage(0);
                    setPage(1);
                  }}
                >
                  <option hidden>Invoice Status</option>
                  <option value="">Invoice Status</option>
                  <option value="2">Paid</option>
                  <option value="1">Pending</option>
                </select>
                <SearchKey
                  onSearchClick={(search) =>
                    handleSearchChange(_searchkeyword, search)
                  }
                  id="searchData"
                  filters={filters}
                  _filters={_filters}
                  placeholder="Search by Invoice Number"
                />
                <RecordCount
                  onSearchClick={(search) =>
                    handleSearchChange(_countValue, search)
                  }
                  id="pageCount"
                  filters={filters}
                  _filters={_filters}
                />
                <ExtractReport
                  downloadFunction={handleExtractReport}
                  styles="extract-report ml-2"
                  text=" Extract report"
                />
                <div className="d-flex align-items-center">
                  <Tooltip title="Clear Filter" placement="bottom">
                    <FaFilterIcon
                      className="filter-icon"
                      role="button"
                      onClick={clearFilter}
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between info-wrap">
            <div className="justify-content-end mt-1 me-2">
              <span className="text-start">
                Total Records : <b>{count}</b>
              </span>
            </div>
            <div className="d-flex gap-3">
              {action && (
                <div className="mt-1">
                  <input
                    type="checkbox"
                    checked={allInvoices}
                    indeterminate={
                      selectedIds.length > 0 && selectedIds.length < data.length
                    }
                    onChange={handleSelectAll}
                  />
                  <span className="ms-2">Select all invoices</span>
                </div>
              )}
              <div className="mt-1">
                <input
                  type="checkbox"
                  checked={!trashed}
                  onChange={() => {
                    _totalPage(0);
                    setPage(1);
                    _trashed(!trashed);
                  }}
                />
                <span className="ms-2">Hide Deleted Data</span>
              </div>
            </div>
          </div>
          <div className="tableContainer">
            <table className="auctionTable nomadTable">
              <thead className="tableHead">
                <tr className="table-row">
                  <th>
                    Invoice No. <br /> Month (period)
                  </th>
                  <th className="maxw-90">
                    Nomad ID <br /> Country
                  </th>
                  <th className="name-text">Nomad Name</th>
                  <th className="time-start">
                    VAT %<br />
                    Amount
                  </th>
                  <th className="maxw-90">Payment status</th>
                  <th>Paid At</th>
                  <th className="time-start">
                    No. of sales
                    {sort === "nomad_sales_count" ? (
                      <span>
                        <FaDownArrow
                          className="ms-1"
                          onClick={() => {
                            _sort("nomad_sales_count");
                            if (sort !== "nomad_sales_count") {
                              setPage(1);
                              _totalPage(0);
                            }
                          }}
                          role="button"
                        />
                        <FaUpArrow
                          className="opacity-40"
                          onClick={() => {
                            _sort("-nomad_sales_count");
                            if (sort !== "-nomad_sales_count") {
                              setPage(1);
                              _totalPage(0);
                            }
                          }}
                          role="button"
                        />
                      </span>
                    ) : sort === "-nomad_sales_count" ? (
                      <span>
                        <FaDownArrow
                          className="ms-1 opacity-40"
                          onClick={() => {
                            _sort("nomad_sales_count");
                            if (sort !== "nomad_sales_count") {
                              setPage(1);
                              _totalPage(0);
                            }
                          }}
                          role="button"
                        />
                        <FaUpArrow
                          role="button"
                          onClick={() => {
                            _sort("-nomad_sales_count");
                            if (sort !== "-nomad_sales_count") {
                              setPage(1);
                              _totalPage(0);
                            }
                          }}
                        />
                      </span>
                    ) : (
                      <span>
                        <FaDownArrow
                          className="ms-1 opacity-40"
                          onClick={() => {
                            _sort("created_at");
                            if (sort !== "created_at") {
                              setPage(1);
                              _totalPage(0);
                            }
                          }}
                          role="button"
                        />
                        <FaUpArrow
                          className="opacity-40"
                          role="button"
                          onClick={() => {
                            _sort("-created_at");
                            if (sort !== "-created_at") {
                              setPage(1);
                              _totalPage(0);
                            }
                          }}
                        />
                      </span>
                    )}
                  </th>
                </tr>
              </thead>
              {loaded ? (
                <div className="d-flex justify-content-center three-dot">
                  <ThreeDots
                    height="150"
                    width="150"
                    radius="9"
                    color="#637df4"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <tbody>
                  {data.length > 0 ? (
                    data.map((item, index) => {
                      let name = "";
                      if (item.user.first_name || item.user.last_name) {
                        name = `${
                          item.user.first_name !== null
                            ? item.user.first_name
                            : ""
                        } ${
                          item.user.last_name !== null
                            ? item.user.last_name
                            : ""
                        }`;
                      } else {
                        name = item.user.pseudo;
                      }
                      return (
                        <tr
                          className={
                            (item.deleted_at ? "deletedRow" : "") + " table-row"
                          }
                          key={index}
                        >
                          <td>
                            {item.invoice_no} <br />
                            {item.month_period}
                          </td>
                          <td className="maxw-90">
                            {item.user.id} <br />
                            <img
                              src={item.user.limited_address.flag_thumbnail}
                              alt=""
                              className="countryflag"
                              title={item.user.limited_address.country}
                            />
                          </td>
                          <td
                            className="name-text"
                            title={name !== "" ? name : "-"}
                          >
                            {name !== "" ? name : "-"}
                          </td>
                          <td className="time-start">
                            <div className="d-flex justify-content-evenly">
                              {action ? (
                                <input
                                  type="checkbox"
                                  checked={selectedIds.includes(item.id)}
                                  onChange={() => handleCheckboxChange(item.id)}
                                />
                              ) : null}
                              <Tooltip
                                title="Nomad Invoice"
                                placement="right-end"
                              >
                                <img
                                  className={
                                    item.subscriptipn_invoice_url !== null
                                      ? "attachment"
                                      : "disabled"
                                  }
                                  src={attach}
                                  alt=""
                                  onClick={() =>
                                    getAttachment(item.nomad_invoice_url)
                                  }
                                />
                              </Tooltip>
                              <div className="mw-120">
                                {item.tax_rate !== null ? (
                                  <p className="d-block">
                                    {item.tax_rate.percentage + " % "}
                                    <span className="light-text">
                                      {formatCurrency(item.tax_amount)}
                                    </span>
                                  </p>
                                ) : (
                                  <p className="d-block">-</p>
                                )}
                                <span className="block">
                                  {formatCurrency(item.subtotal)}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="maxw-90">
                            <div className="position-relative">
                              <span
                                className={
                                  "status " +
                                  (item.status === 1
                                    ? "status-progress"
                                    : "status-active")
                                }
                                title={
                                  item.status === 1
                                    ? "Pending"
                                    : item.status === 2
                                    ? "Paid"
                                    : "-"
                                }
                              ></span>
                              {item.error ? (
                                <img
                                  src={error}
                                  className="errorIcon position-absolute"
                                  onClick={() => _popup(item.error)}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </td>
                          <td>
                            {item.paid_at
                              ? moment(item.paid_at)
                                  .tz("Europe/Amsterdam")
                                  .format("DD-MM-YYYY HH:mm")
                              : "-"}
                          </td>
                          <td>{item.nomad_sales_count}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <NoRecord />
                  )}
                  <tr className="table-row summary-row">
                    <td></td>
                    <td className="maxw-90"></td>
                    <td className="name-text"></td>
                    <td className="time-start d-flex flex-column">
                      <b title="Total Amount">
                        {totalAmount
                          ? formatCurrency(totalAmount.totalAmount)
                          : "-"}
                      </b>
                      <b title="Total VAT">
                        {totalAmount
                          ? formatCurrency(totalAmount.totalCommissionVatAmount)
                          : "-"}
                      </b>
                    </td>
                    <td className="maxw-90"></td>
                    <td></td>
                    <td>
                      <b>{nomadSalesCount !== "" ? nomadSalesCount : "-"}</b>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
          <div>
            {totalPage > 1 ? (
              <Pagination
                totalPages={totalPage}
                page={page}
                onPageClick={(page) => {
                  setPage(page + 1);
                }}
                key={totalPage}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nomadtable;
