import { Button, Tooltip } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import {
  FaDownArrow,
  FaFilterIcon,
  FaUpArrow,
} from "../../../assets/Images/icon";
import arrowDown from "../../../assets/Images/icons/faChevronDown.svg";
import arrowUp from "../../../assets/Images/icons/faChevronUp.svg";
import MainLayout from "../../../assets/Layout/MainLayout";
import SearchKey from "../../../assets/SharedComponents/Search/SearchKey";
import { ClassicAdsListWrapper } from "./style/classicAdsList";
import noImage from "../../../assets/Images/image/noImage.svg";
import * as Constant from "../../../config/constant";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import NoRecord from "../../../assets/SharedComponents/Table/NoRecord";
import moment from "moment";
import Pagination from "../../../assets/SharedComponents/Pagination";
import { ThreeDots } from "react-loader-spinner";
import { confirmAlert } from "react-confirm-alert";
import SearchableObjDropdown from "../../../assets/SharedComponents/SearchableDropdown/SearchableObjDropdown";
import RecordCount from "../../../assets/SharedComponents/RecordCount/RecordCount";
import SearchableObjDropdownWithImage from "../../../assets/SharedComponents/SearchableDropdown/SearchableObjDropdownWithImage";
import Popup from "./Components/Popup";
import { getName } from "../../../core/utils/getName/getName";
import Sorting from "../../../assets/SharedComponents/Sorting/Sorting";
import StaticFilter from "../../../assets/SharedComponents/Filters/StaticFilter";
import UserFilter from "../../../assets/SharedComponents/Filters/UserFilter";
import SubsidiaryFilter from "../../../assets/SharedComponents/Filters/SubsidiaryFilter";
import BrandFilter from "../../../assets/SharedComponents/Filters/BrandFilter";
import ModelFilter from "../../../assets/SharedComponents/Filters/ModelFilter";
import download from "downloadjs";
import AdVisitorModal from "../../../assets/SharedComponents/AdVisitorModal/adVisitorModal";
import { downloadFileError, getProductTypeName } from "../../../core/utils";
import ProductTypeFilter from "../../../assets/SharedComponents/Filters/ProductTypeFilter";
import ExtractReport from "../../../assets/SharedComponents/ExtractReport/ExtractReport";

const validateStatus = Object.keys(Constant.adsValidStatus).map((key) => ({
  name: key,
  value: Constant.adsValidStatus[key],
}));
const adsStatus = Object.keys(Constant.adsOnlineStatus).map((key) => ({
  name: key,
  value: Constant.adsOnlineStatus[key],
}));

const ClassicAdsList = () => {
  const [classicAds, _classicAds] = useState([]);
  const [loaded, _loaded] = useState(false);

  const [totalPage, _totalPage] = useState(0);
  const [total, _total] = useState(0);
  const [page, setPage] = useState(1);

  const [productValue, _productValue] = useState("");
  const [brandID, _brandID] = useState("");
  const [modelID, _modelID] = useState("");

  const [userID, _userID] = useState("");
  const [subID, _subID] = useState("");

  const [searchkeyword, _searchkeyword] = useState("");
  const [countValue, _countValue] = useState("");

  const [trashed, _trashed] = useState(true);
  const [validated, _validated] = useState("");
  const [isOnline, _isOnline] = useState("");
  const [deletedbyRUS, _deletedbyRUS] = useState(false);
  const [filters, _filters] = useState(false);
  const [sort, _sort] = useState("-created_at");

  const [action, _action] = useState(false);
  const [checked, _checked] = useState(false);
  const [dataDeleted, _dataDeleted] = useState(false);

  const [popup, _popup] = useState(false);

  const [adId, _adId] = useState("");
  const [adsvalidated, _adsvalidated] = useState([]);

  const [userAccess, _userAccess] = useState(
    JSON.parse(localStorage.getItem("permissions"))
  );

  let classicAdsAccess = userAccess.find((o) => o.slug === "classic_ads");

  useEffect(() => {
    getClassicAds();
    _dataDeleted(false);
  }, [
    page,
    searchkeyword,
    countValue,
    sort,
    dataDeleted,
    brandID,
    modelID,
    deletedbyRUS,
    userID,
    subID,
    trashed,
    validated,
    isOnline,
    productValue,
  ]);

  useEffect(() => {
    if (adId !== "") {
      validate(adId);
    }
  }, [adId]);

  //applied filters
  function getFilters() {
    return (
      `?page=${page}&per_page=${countValue}&keyword=${searchkeyword}&sort=${sort}` +
      `&filter[model_id]=${modelID}&filter[brand_id]=${brandID}&filter[deleted_by_admin]=${deletedbyRUS}` +
      `&filter[user_created_id]=${userID}&filter[product_type_id]=${productValue}&filter[current_sub_id]=${subID}&with_trashed=${
        trashed === true ? 1 : 0
      }` +
      `&filter[validated]=${validated}&filter[is_online]=${isOnline}`
    );
  }

  const getClassicAds = () => {
    _loaded(true);
    _adsvalidated([]);
    API.get(`${APP_URLS.LIST_CLASSIC_ADS}${getFilters()}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _classicAds([]);
          _action(false);
          _checked(false);
          resp.data.data.forEach((element) => {
            element.checked = false;
            _classicAds((old) => {
              return [...old, element];
            });
          });
          _totalPage(resp.data.last_page);
          _total(resp.data.total);
          _loaded(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loaded(false);
        }
      })
      .catch(function (error) {
        _loaded(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map(
              (error, index) => (error_message = resp.data.data[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleSelect = (id) => {
    let data = [...classicAds];
    let val = data.find((o) => o.id === id);
    if (val !== undefined) {
      val.checked = !val.checked;
    }
    _classicAds(data);
  };

  const selectAll = (val) => {
    _checked(!val);
    let data = [...classicAds];
    data.forEach((element) => {
      if (element.deleted_at === null) {
        element.checked = !val ? true : false;
      }
    });
    _classicAds(data);
  };

  const handleDelete = () => {
    let arr = [];
    let data = [...classicAds];
    data.forEach((element) => {
      if (element.checked === true) {
        arr.push(element.id);
      }
    });
    if (arr.length > 0) {
      _popup(true);
    } else {
      toast.error("If you want to delete, select some ads first", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleSearchChange = (func, searchValue) => {
    func(searchValue);
    if (searchValue !== "") {
      _totalPage(0);
      setPage(1);
    }
  };

  const setChangeValue = (func, item) => {
    func(item.split("_")[0]);
    _totalPage(0);
    setPage(1);
  };

  const setValue = (func, item) => {
    func(item);
    _totalPage(0);
    setPage(1);
  };

  const clearFilter = () => {
    _filters(true);
    handleSearchChange(_countValue, "");
    handleSearchChange(_searchkeyword, "");
    _sort("-created_at");
    _brandID("");
    _modelID("");
    _userID("");
    _subID("");
    _deletedbyRUS(false);
    _trashed(true);
    _validated("");
    _isOnline("");
    _productValue("");
    _sort("-created_at");
    if (
      searchkeyword !== "" ||
      countValue !== "" ||
      sort !== "-created_at" ||
      deletedbyRUS !== false ||
      brandID !== "" ||
      modelID !== "" ||
      userID !== "" ||
      subID !== "" ||
      trashed !== true ||
      validated !== "" ||
      isOnline !== ""
    ) {
      _totalPage(0);
      setPage(1);
    }
  };

  const validate = (index) => {
    confirmAlert({
      title: "Confirm to validate",
      message: `Are you sure you want to validate this ad?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            API.post(
              APP_URLS.VALIDATE_CLASSIC_AD + `/${index}/validate?_method=PATCH`
            )
              .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                  toast.success("Ad has been validated successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  _adsvalidated((old) => [...old, resp.data.values[0]]);
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                  {
                    Object.keys(resp.data.data).map(
                      (error, index) =>
                        (error_message = resp.data.data[error][0])
                    );
                  }
                } else if (resp.data.data?.error !== undefined) {
                  error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                  error_message = resp.data.error;
                } else {
                  error_message = resp.data.message;
                }
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          },
        },
        {
          label: "No",
          onClick: () => {
            _adId("");
          },
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
      overlayClassName: "overlay-custom-class-name",
    });
  };

  const getValidatedUser = (id) => {
    let data = adsvalidated.find((o) => o.id === id);
    if (data !== undefined) {
      return {
        validated_by: getName(data.validated_by),
        validated_at: data.validated_at,
      };
    } else {
      return false;
    }
  };

  const handleSort = (sortType) => {
    _sort(sortType);
    setPage(1);
    _totalPage(0);
  };

  const handleValidated = (value) => {
    _totalPage(0);
    setPage(1);
    _validated(value);
  };

  const handleOnlineStatus = (value) => {
    _totalPage(0);
    setPage(1);
    _isOnline(value);
  };

  const handleExtractReport = () => {
    API.get(
      `${APP_URLS.LIST_CLASSIC_ADS}/download_classic_ads_report${getFilters()}`,
      { responseType: "blob" }
    )
      .then((res) => {
        const resp = res.data;
        if (resp !== null) {
          toast.success("Downloading. Please wait", {
            position: toast.POSITION.TOP_RIGHT,
          });
          const content = res.headers["content-type"];
          const currentDate = moment().format("YYYY-MM-DD HH-mm-ss");
          const fileName = `${currentDate}_classic_ads_report.xlsx`;
          download(resp, fileName, content);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(async function (error) {
        let error_message = await downloadFileError(error);
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <ClassicAdsListWrapper>
      {popup === true ? (
        <Popup _popup={_popup} data={classicAds} _dataDeleted={_dataDeleted} />
      ) : (
        ""
      )}
      <div className="auctionMainContainer">
        <div className="row mt-4">
          <div className="col-md-4 offset-md-4 mb-2">
            <h1>CLASSIC ADS LIST</h1>
          </div>
          {classicAdsAccess?.permissions.find((o) => o.slug === "delete") ? (
            <div className="col-md-4 d-flex justify-content-end align-items-center mb-5">
              {action ? (
                <button
                  className="completeBtn me-4"
                  onClick={() => {
                    _action(false);
                    selectAll(true);
                  }}
                >
                  Done
                </button>
              ) : (
                ""
              )}
              <div className="actionAuction">
                <div className={(action ? "btnActive" : "") + " actionBtns"}>
                  <button
                    className="actionBtn"
                    onClick={() => {
                      _action(!action);
                      selectAll(true);
                    }}
                  >
                    Action
                    <img
                      src={arrowUp}
                      alt=""
                      className={(action ? "close" : "open") + " ms-2"}
                    />
                  </button>
                </div>
                <div className="DelAction">
                  {action ? (
                    <button
                      className={(action ? "" : "") + " actionBtn"}
                      onClick={handleDelete}
                    >
                      Delete
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="d-flex align-items-center justify-content-end info-wrap">
          <div className="justify-content-end mb-2 d-flex gap-2 mb-2 select-info align-items-center">
            <UserFilter
              _filters={_filters}
              filters={filters}
              setUser={(val) => setValue(_userID, val)}
              user={userID}
              searchByPseudoName={true}
            />
            <UserFilter
              _filters={_filters}
              filters={filters}
              setUser={(val) => setValue(_userID, val)}
              user={userID}
            />
            <SubsidiaryFilter
              _filters={_filters}
              filters={filters}
              setSub={(val) => setValue(_subID, val)}
              sub={subID}
            />
            <ProductTypeFilter
              handleChange={(e) => {
                _productValue(e.target.value);
                _brandID("");
                _modelID("");
                _totalPage(0);
                setPage(1);
              }}
              value={productValue}
            />
            <BrandFilter
              _filters={_filters}
              filters={filters}
              brand={brandID}
              setBrand={(val) => setChangeValue(_brandID, val)}
              productValue={productValue}
            />
            <ModelFilter
              _filters={_filters}
              filters={filters}
              brandID={brandID}
              modelID={modelID}
              setChangeValue={(val) => setChangeValue(_modelID, val)}
            />
            <StaticFilter
              placeholder="Ads Validity"
              data={validateStatus}
              handleChange={(e) => {
                handleValidated(e.target.value);
              }}
              value={validated}
            />
            <StaticFilter
              placeholder="Ads Status"
              data={adsStatus}
              handleChange={(e) => {
                handleOnlineStatus(e.target.value);
              }}
              value={isOnline}
            />
            <SearchKey
              onSearchClick={(search) =>
                handleSearchChange(_searchkeyword, search)
              }
              id="searchData"
              filters={filters}
              _filters={_filters}
              placeholder="Search by Title"
            />
            <div className={deletedbyRUS === true ? "link" : "filter"}>
              <button
                className={
                  deletedbyRUS === true
                    ? "filterBtn filterBtnActive me-2"
                    : "filterBtn me-2"
                }
                onClick={() => {
                  _totalPage(0);
                  setPage(1);
                  _deletedbyRUS(!deletedbyRUS);
                }}
              >
                Only deleted by CTB
              </button>
              <RecordCount
                onSearchClick={(search) =>
                  handleSearchChange(_countValue, search)
                }
                id="pageCount"
                filters={filters}
                _filters={_filters}
              />
            </div>
            <ExtractReport
              downloadFunction={handleExtractReport}
              styles="extract-report ml-2"
              text=" Extract report"
            />
            <Tooltip title="Clear Filter" placement="bottom">
              <FaFilterIcon
                className="filter-icon"
                role="button"
                onClick={clearFilter}
              />
            </Tooltip>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between info-wrap">
          <div className="mt-1">
            <span className="text-start">
              Total Records : <b>{total}</b>
            </span>
          </div>
          <div className="mt-1">
            {action ? (
              <>
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={() => selectAll(checked)}
                />
                <span className="ms-2 me-3">Select All</span>
              </>
            ) : (
              ""
            )}
            <input
              type="checkbox"
              checked={!trashed}
              onChange={() => {
                _totalPage(0);
                setPage(1);
                _trashed(!trashed);
              }}
            />
            <span className="ms-2">Hide Deleted Data</span>
          </div>
        </div>
        <div className="tableContainer">
          <table className="auctionTable">
            <thead className="tableHead">
              <tr className="table-row">
                {classicAdsAccess?.permissions.find(
                  (o) => o.slug === "delete"
                ) ? (
                  action ? (
                    <th className="deleteAction"></th>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                <th className="time-start">
                  Image
                  <br />
                  Ad ID
                </th>
                <th className="time-start">Title</th>
                <th className="time-start">
                  Brand
                  <br />
                  Model
                  <br />
                  Version
                </th>
                <th className="w-90 time-start">Product Type</th>
                <th className="w-150 time-start">
                  New or Used
                  <br />
                  Registration date
                  <br />
                  Km
                </th>
                <th className="time-start">
                  Price Excl VAT
                  <br />
                  VAT %
                  <br />
                  Price Incl VAT
                </th>
                <th className="time-start">
                  <p className="fieldTitle">Seller Details</p>
                  Creator Name <span className="fieldTitle">(Id)</span>
                  <br />
                  {`${Constant.userTypeName.DEALER} Name `}
                  <span className="fieldTitle">(Id)</span>
                </th>
                <th className="time-start">
                  Seller type
                  <br />
                  Country
                </th>
                <th className="time-start">
                  <div className="d-flex flex-column align-items-center">
                    <span>
                      Created At
                      <Sorting
                        sortType="created_at"
                        sort={sort}
                        handleSort={handleSort}
                      />
                    </span>
                    <span>
                      Updated At
                      <Sorting
                        sortType="updated_at"
                        sort={sort}
                        handleSort={handleSort}
                      />
                    </span>
                  </div>
                </th>
                <th className="time-start">
                  <div className="d-flex flex-column align-items-center">
                    <span>
                      Deleted At
                      <Sorting
                        sortType="deleted_at"
                        sort={sort}
                        handleSort={handleSort}
                      />
                    </span>
                    <span>Deleted by User</span>
                    <span>Deleted by RUS</span>
                  </div>
                </th>
                <th className="time-start">Validate</th>
                <th className="time-start">Status</th>
                <th className="time-start">
                  <div className="d-flex flex-column align-items-center">
                    <span>
                      Chat Count
                      <Sorting
                        sortType="chat_count"
                        sort={sort}
                        handleSort={handleSort}
                      />
                    </span>
                  </div>
                </th>
                <th className="time-start">
                  <div className="d-flex flex-column align-items-center">
                    <span>
                      No of views
                      <Sorting
                        sortType="click"
                        sort={sort}
                        handleSort={handleSort}
                      />
                    </span>
                  </div>
                </th>
                <th className="time-start">
                  <div className="d-flex flex-column align-items-center">
                    <span>
                      No of calls
                      <Sorting
                        sortType="call_count"
                        sort={sort}
                        handleSort={handleSort}
                      />
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            {loaded ? (
              <div className="d-flex justify-content-center three-dot">
                <ThreeDots
                  height="150"
                  width="150"
                  radius="9"
                  color="#637df4"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              <tbody>
                {classicAds.length > 0 ? (
                  classicAds.map((item, index) => {
                    let date = `${
                      item.owner_vehicle.vehicle_first_register_month !== null
                        ? item.owner_vehicle.vehicle_first_register_month
                        : ""
                    } ${
                      item.owner_vehicle.vehicle_first_register_year !== null
                        ? item.owner_vehicle.vehicle_first_register_year
                        : ""
                    }`;
                    let name = "";
                    let id = "";
                    let deletedByUser = "";
                    let deletedByRUS = "";
                    if (item.created_by !== null) {
                      if (
                        item.created_by.first_name ||
                        item.created_by.last_name
                      ) {
                        name = `${
                          item.created_by.first_name !== null
                            ? item.created_by.first_name
                            : ""
                        } ${
                          item.created_by.last_name !== null
                            ? item.created_by.last_name
                            : ""
                        }`;
                      } else {
                        name = item.created_by.pseudo;
                      }
                      id = item.created_by.id;
                    }
                    if (item.delete_activity_log !== null) {
                      if (
                        item.delete_activity_log.first_name ||
                        item.delete_activity_log.last_name
                      ) {
                        deletedByUser = `${
                          item.delete_activity_log.first_name !== null
                            ? item.delete_activity_log.first_name
                            : ""
                        } ${
                          item.delete_activity_log.last_name !== null
                            ? item.delete_activity_log.last_name
                            : ""
                        }`;
                      } else if (item.delete_activity_log.pseudo) {
                        deletedByUser = item.delete_activity_log.pseudo;
                      } else {
                        deletedByUser = "-";
                        deletedByRUS = item.delete_activity_log.name;
                      }
                    }
                    const isOnline = item?.is_online;
                    const disable = item.deleted_at || !isOnline;
                    return (
                      <tr
                        className={
                          (item.deleted_at ? "deletedRow" : "") + " table-row"
                        }
                        key={item.id}
                      >
                        {action ? (
                          !item.deleted_at ? (
                            <td className="deleteAction time-start">
                              <span className="deleteAuction">
                                <input
                                  type="checkbox"
                                  className="checkbox-round"
                                  id=""
                                  name="delete"
                                  checked={item.checked}
                                  onChange={(e) => handleSelect(item.id)}
                                />
                              </span>
                            </td>
                          ) : (
                            <div className="deleteAction"></div>
                          )
                        ) : (
                          ""
                        )}
                        <td className="time-start">
                          <div className="d-flex flex-column align-items-center">
                            {
                              <img
                                src={item?.media ? item.media : noImage}
                                alt="car"
                                className="auctionEventImage"
                              />
                            }
                            <span className="my-1">{item.id}</span>
                          </div>
                        </td>
                        <td
                          className="time-start"
                          onClick={() =>
                            disable
                              ? ""
                              : window.open(
                                  `${process.env.REACT_APP_FRONTOFFICE_URL_HTTPS}/en/public-ads/${item.id}`,
                                  "_blank"
                                )
                          }
                        >
                          <b className={disable ? "disabled" : "link"}>
                            {item.owner_vehicle.title}
                          </b>
                        </td>
                        <td className="time-start">
                          {item.owner_vehicle.model !== null
                            ? item.owner_vehicle.model.brand_car.name
                            : "-"}
                          <br />
                          {item.owner_vehicle.model !== null
                            ? item.owner_vehicle.model.model_name
                            : "-"}
                          <br />
                          {item.owner_vehicle.version_car !== null
                            ? item.owner_vehicle.version_car.version_name
                            : "-"}
                        </td>
                        <td className="w-90 time-start">
                          {item?.owner_vehicle &&
                          item?.owner_vehicle?.product_type_id
                            ? getProductTypeName(
                                item.owner_vehicle.product_type_id
                              )
                            : "-"}
                        </td>
                        <td className="w-150 time-start">
                          {item.owner_vehicle.new_vehicle === true
                            ? "New"
                            : "Used"}
                          <br />
                          {date !== " " ? date : "-"}
                          <br />
                          {item.owner_vehicle.vehicle_mileage
                            ? `${item.owner_vehicle.vehicle_mileage} KM`
                            : "-"}
                        </td>
                        <td className="time-start">
                          {item.owner_vehicle.vehicle_deductible_vat === 1
                            ? `€ ${item.priceExcludingVat}`
                            : item.public_price !== null
                            ? `€ ${item.public_price}`
                            : "-"}
                          <br />
                          {item.owner_vehicle.vehicle_deductible_vat === 1
                            ? item.owner_vehicle.current_sub !== undefined &&
                              item.owner_vehicle.current_sub !== null
                              ? `${item.owner_vehicle.current_sub.limited_address.vat_rate} %`
                              : item.owner_vehicle.current_user !== undefined &&
                                item.owner_vehicle.current_user !== null
                              ? `${item.owner_vehicle.current_user.limited_address.vat_rate} %`
                              : "-"
                            : "-"}

                          <br />
                          {item.owner_vehicle.vehicle_deductible_vat === 1
                            ? item.public_price !== null
                              ? `€ ${item.public_price}`
                              : "-"
                            : "-"}
                        </td>
                        <td className="time-start">
                          {name !== "" ? (
                            <>
                              {name ? (
                                <a
                                  className="link"
                                  href={`/detail-member/${id}`}
                                  target="_blank"
                                >
                                  <b>{name}</b>
                                </a>
                              ) : null}
                              <span className="fieldTitle">({id})</span>
                            </>
                          ) : (
                            "-"
                          )}
                          <br />
                          {item.owner_vehicle.current_sub !== null ? (
                            <>
                              <a
                                className="link"
                                target="_blank"
                                href={`/company-details/${item.owner_vehicle.current_sub.id}`}
                              >
                                <b>
                                  {item.owner_vehicle.current_sub.name + " "}
                                </b>
                              </a>
                              <span className="fieldTitle">
                                ({item.owner_vehicle.current_sub.id})
                              </span>
                            </>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="flag-img time-start">
                          {item.owner_vehicle.current_sub !== undefined &&
                          item.owner_vehicle.current_sub !== null
                            ? Constant.userTypeName.DEALER
                            : Constant.userTypeName.PRIVATE}
                          <br />
                          {item.owner_vehicle.current_sub !== undefined &&
                          item.owner_vehicle.current_sub !== null ? (
                            <img
                              src={
                                item.owner_vehicle.current_sub.limited_address
                                  .flag_thumbnail
                              }
                              title={
                                item.owner_vehicle.current_sub.limited_address
                                  .country
                              }
                              className="countryflag"
                              alt="flag"
                            />
                          ) : item.created_by !== undefined &&
                            item.created_by !== null &&
                            item.created_by.limited_address !== undefined &&
                            item.created_by.limited_address !== null ? (
                            <img
                              src={
                                item.created_by.limited_address.flag_thumbnail
                              }
                              className="countryflag"
                              alt="flag"
                              title={item.created_by.limited_address.country}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="time-start">
                          <span className="d-block">
                            {moment(item.created_at)
                              .tz("Europe/Amsterdam")
                              .format("DD-MM-YYYY HH:mm")}
                          </span>
                          <span className="block">
                            {moment(item.updated_at)
                              .tz("Europe/Amsterdam")
                              .format("DD-MM-YYYY HH:mm")}
                          </span>
                        </td>
                        <td className="time-start">
                          <span className="d-block">
                            {item.deleted_at ? (
                              <span>
                                {moment(item.deleted_at)
                                  .tz("Europe/Amsterdam")
                                  .format("DD-MM-YYYY HH:mm")}
                              </span>
                            ) : (
                              "-"
                            )}
                          </span>
                          <span className="d-block">
                            <b>
                              {deletedByUser !== "" &&
                              deletedByUser !== undefined
                                ? deletedByUser.charAt(0).toUpperCase() +
                                  deletedByUser.slice(1)
                                : "-"}
                            </b>
                          </span>
                          <span className="d-block">
                            <b>
                              {deletedByRUS !== "" &&
                              deletedByUser !== undefined
                                ? deletedByRUS
                                : "-"}
                            </b>
                          </span>
                        </td>
                        <td className="time-start">
                          {item.validated_at !== null ? (
                            <>
                              <span className="d-block">
                                {getName(item.validated_by)
                                  .charAt(0)
                                  .toUpperCase() +
                                  getName(item.validated_by).slice(1)}
                              </span>
                              <span className="d-block">
                                {moment(item.validated_at)
                                  .tz("Europe/Amsterdam")
                                  .format("DD-MM-YYYY HH:mm")}
                              </span>
                            </>
                          ) : classicAdsAccess?.permissions.find(
                              (o) => o.slug === "update"
                            ) && item.deleted_at === null ? (
                            adsvalidated.length > 0 &&
                            getValidatedUser(item.id) !== false ? (
                              <>
                                <span className="d-block">
                                  {getValidatedUser(item.id)
                                    .validated_by.charAt(0)
                                    .toUpperCase() +
                                    getValidatedUser(
                                      item.id
                                    ).validated_by.slice(1)}
                                </span>
                                <span className="d-block">
                                  {moment(
                                    getValidatedUser(item.id).validated_at
                                  )
                                    .tz("Europe/Amsterdam")
                                    .format("DD-MM-YYYY HH:mm")}
                                </span>
                              </>
                            ) : (
                              <input
                                type="checkbox"
                                checked={item.id === adId ? true : false}
                                onChange={(e) => {
                                  if (item.id === adId) {
                                    _adId("");
                                  } else {
                                    _adId(item.id);
                                  }
                                }}
                              />
                            )
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>
                          <span
                            className={`status ${
                              isOnline ? "online" : "offline"
                            }`}
                          ></span>
                        </td>
                        <td className="time-start">
                          {item.chat_count ? (
                            <AdVisitorModal
                              count={item.chat_count}
                              title={"Chats"}
                              id={item.id}
                              type={Constant.visitorsMethodsTypes.chat}
                            />
                          ) : (
                            0
                          )}
                        </td>
                        <td className="time-start">
                          {item.click ? (
                            <AdVisitorModal
                              count={item.click}
                              title={"Views"}
                              id={item.id}
                              type={Constant.visitorsMethodsTypes.view}
                            />
                          ) : (
                            0
                          )}
                        </td>
                        <td className="time-start">
                          {item.call_count ? (
                            <AdVisitorModal
                              count={item.call_count}
                              title={"Calls"}
                              id={item.id}
                              type={Constant.visitorsMethodsTypes.call}
                            />
                          ) : (
                            0
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <NoRecord />
                )}
              </tbody>
            )}
          </table>
        </div>
        <div>
          {totalPage > 1 ? (
            <Pagination
              totalPages={totalPage}
              page={page}
              onPageClick={(selectedPage) => setPage(selectedPage + 1)}
              key={totalPage}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </ClassicAdsListWrapper>
  );
};

export default ClassicAdsList;
