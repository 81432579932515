import { Button } from "@mui/material";
import React, { useState } from "react";

const ExtractReport = ({
  text = "Extract report",
  styles,
  downloadFunction,
}) => {
  // Get user permissions from local storage
  const [userAccess, _userAccess] = useState(
    JSON.parse(localStorage.getItem("permissions"))
  );

  // Check if user has permission to extract reports
  const extractReportAccess = userAccess?.find(
    (o) => o.slug === "extract_reports"
  );

  const hasReadPermission = extractReportAccess?.permissions?.some(
    (o) => o.slug === "read"
  );

  if (!hasReadPermission) return null; // Hide button if the user lacks access

  return (
    <Button onClick={downloadFunction} className={styles} variant="outlined">
      {text}
    </Button>
  );
};

export default ExtractReport;
