import React, { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import { APP_URLS } from "../../api/url";
import API from "../../api/axios";
import {
  paymentStatus,
  pendingAction,
  userTypeTypo,
  vehicleStatus,
} from "../../config/constant";
import { toast } from "react-toastify";
import RecordCount from "../../assets/SharedComponents/RecordCount/RecordCount";
import { Button, Tooltip } from "@mui/material";
import Pagination from "../../assets/SharedComponents/Pagination";
import NoRecord from "../../assets/SharedComponents/Table/NoRecord";
import { FaFilterIcon } from "../../assets/Images/icon";
import StaticFilter from "../../assets/SharedComponents/Filters/StaticFilter";
import download from "downloadjs";
import moment from "moment";
import { DepositHistoryWrapper } from "./style";
import SearchKey from "../../assets/SharedComponents/Search/SearchKey";
import SellerCustomersFilter from "../../assets/SharedComponents/Filters/SellerCustomersFilter";
import BuyerCustomersFilter from "../../assets/SharedComponents/Filters/BuyerCustomersFilter";
import { confirmAlert } from "react-confirm-alert";
import attach from "../../assets/Images/icons/svgIcon/Attach.svg";
import { CurrencyDisplay, downloadFileError } from "../../core/utils";
import ExtractReport from "../../assets/SharedComponents/ExtractReport/ExtractReport";

const paymentStatusData = Object.keys(paymentStatus).map((key) => ({
  name: paymentStatus[key].label,
  value: paymentStatus[key].id,
}));
const vehicleStatusData = Object.keys(vehicleStatus).map((key) => ({
  name: vehicleStatus[key].label,
  value: vehicleStatus[key].id,
}));
const pendingActionData = Object.keys(pendingAction).map((key) => ({
  name: pendingAction[key].label,
  value: pendingAction[key].id,
}));

const currDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
  .toJSON()
  .slice(0, 10);

const DepositHistory = () => {
  const [data, _data] = useState([]);
  const [searchkeyword, _searchkeyword] = useState("");
  const [countValue, _countValue] = useState("");
  const [selectedPaymentStatus, _selectedPaymentStatus] = useState("");
  const [selectedVehicleStatus, _selectedVehicleStatus] = useState("");
  const [selectedPendingAction, _selectedPendingAction] = useState("");
  const [page, setPage] = useState(1);
  const [total, _total] = useState(0);
  const [totalPage, _totalPage] = useState(0);
  const [filters, _filters] = useState(false);
  const [loaded, _loaded] = useState(false);
  const [buyerID, _buyerID] = useState("");
  const [sellerID, _sellerID] = useState("");
  const [buyer, setBuyer] = useState(null);
  const [seller, setSeller] = useState(null);
  const [taxRate, _taxRate] = useState("");
  const [searchBuyerKeyword, _searchBuyerKeyword] = useState("");
  const [searchSellerKeyword, _searchSellerKeyword] = useState("");
  const [totalAmountData, setTotalAmountData] = useState({});
  const [dateRange, setDateRange] = useState({
    start: "",
    end: "",
  });

  useEffect(() => {
    getDepositHistory();
  }, [
    page,
    dateRange,
    searchkeyword,
    countValue,
    seller,
    buyer,
    selectedPaymentStatus,
    selectedVehicleStatus,
    selectedPendingAction,
  ]);

  const getDepositHistory = async () => {
    _loaded(true);
    const apiUrl = getRestUrl();
    try {
      const res = await API.get(`${APP_URLS.DEPOSIT_HISTORY}?${apiUrl}`);
      const resp = res.data;
      if (resp.success === true) {
        _data(resp.data.deposits.data);
        setTotalAmountData(resp.data.total_amount);
        _taxRate(resp.data.tax_rate.percentage);
        _totalPage(resp.data.deposits.last_page);
        _total(resp.data.deposits.total);
      } else {
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      const resp = error.response;
      let errorMessage = "";

      if (resp?.data?.data) {
        if (resp.data.data.message) {
          errorMessage = resp.data.data.message;
        } else {
          Object.keys(resp.data.data).forEach((key) => {
            errorMessage = resp.data.data[key][0];
          });
        }
      } else if (resp?.data?.error) {
        errorMessage = resp.data.error;
      } else if (resp?.error) {
        errorMessage = resp.error;
      } else {
        errorMessage =
          resp?.message || resp.data.message || "An unknown error occurred!!!!";
      }

      toast.error(errorMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      _loaded(false);
    }
  };

  const handleSearchChange = (updateState, searchValue) => {
    updateState(searchValue);
    if (
      (updateState === _countValue && searchValue !== countValue) ||
      (updateState === _searchkeyword && searchValue !== searchkeyword)
    ) {
      if (searchValue !== "") {
        _totalPage(0);
        setPage(1);
      }
    }
  };

  const clearFilter = () => {
    _filters(true);
    setSeller(null);
    setBuyer(null);
    _selectedPaymentStatus("");
    _selectedVehicleStatus("");
    _selectedPendingAction("");
    _buyerID("");
    _sellerID("");
    handleSearchChange(_countValue, "");
    handleSearchChange(_searchkeyword, "");
    if (searchkeyword !== "" || countValue !== "") {
      _totalPage(0);
      setPage(1);
    }
  };

  const setChangeValue = (updateState, item) => {
    updateState(item);
    _totalPage(0);
    setPage(1);
  };

  //filter params
  function getRestUrl() {
    let urlParams = {};
    if (dateRange.start)
      urlParams["filter[from]"] = moment(dateRange.start).format("YYYY-MM-DD");
    if (dateRange.end)
      urlParams["filter[to]"] = moment(dateRange.end).format("YYYY-MM-DD");
    if (selectedPaymentStatus)
      urlParams["filter[payment_status]"] = selectedPaymentStatus;
    if (selectedVehicleStatus)
      urlParams["filter[vehicle_status]"] = selectedVehicleStatus;
    if (selectedPendingAction)
      urlParams["filter[pending_action]"] = selectedPendingAction;
    if (countValue) urlParams["per_page"] = countValue;
    if (page > 1) urlParams["page"] = page;
    if (searchkeyword !== "") urlParams["filter[keyword]"] = searchkeyword;

    // Add buyer and seller specific params
    if (buyer) {
      if (buyer.source === userTypeTypo.USER) {
        urlParams["filter[buyer_user_id]"] = buyer.value;
      } else if (buyer.source === userTypeTypo.SUBSIDIARY) {
        urlParams["filter[buyer_sub_id]"] = buyer.value;
      }
    }

    if (seller) {
      if (seller.source === userTypeTypo.USER) {
        urlParams["filter[seller_user_id]"] = seller.value;
      } else if (seller.source === userTypeTypo.SUBSIDIARY) {
        urlParams["filter[seller_sub_id]"] = seller.value;
      }
    }

    const restUrl = new URLSearchParams(urlParams);
    return restUrl;
  }

  const handleExtractReport = () => {
    const apiurl = getRestUrl();
    API.get(`${APP_URLS.DEPOSIT_HISTORY_EXTRACT}?${apiurl}`, {
      responseType: "blob",
    })
      .then((res) => {
        const resp = res.data;
        if (resp !== null) {
          toast.success("Downloading. Please wait", {
            position: toast.POSITION.TOP_RIGHT,
          });
          const content = res.headers["content-type"];
          const currentDate = moment().format("YYYY-MM-DD HH-mm-ss");
          const fileName = `${currentDate}_vehicle_reservation_history_report.xlsx`;
          download(resp, fileName, content);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(async function (error) {
        let error_message = await downloadFileError(error);
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const validatePayment = (status, id) => {
    let message = "";

    if (status === vehicleStatus.SELLER_NO_ACTION.id) {
      message =
        "Are you sure about proceeding with the acceptance of this vehicle on behalf of the seller?";
    } else if (status === vehicleStatus.BUYER_NO_ACTION.id) {
      message =
        "Are you sure about proceeding with the acceptance of this vehicle on behalf of the buyer?";
    }
    confirmAlert({
      title: "Confirmation of Vehicle Acceptance",
      message: message,
      closeOnEscape: true,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            API.post(
              `${APP_URLS.CONFIRM_HOTDEAL_PAYMENT}/${id}/${status}?_method=patch`
            )
              .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                  toast.success("Vehicle has been accepted successfully.", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  getDepositHistory();
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                  {
                    Object.keys(resp.data.data).map(
                      (error, index) =>
                        (error_message = resp.data.data[error][0])
                    );
                  }
                } else if (resp.data.data?.error !== undefined) {
                  error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                  error_message = resp.data.error;
                } else {
                  error_message = resp.data.message;
                }
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-custom-class-name",
    });
  };

  //setting seller value
  const setSellerValue = (item) => {
    setSeller(item);
    _sellerID(item.value);
    _totalPage(0);
    setPage(1);
    handleSearchChange(_searchkeyword, "");
  };

  //setting buyer value
  const setBuyerValue = (item) => {
    setBuyer(item);
    _buyerID(item.value);
    _totalPage(0);
    setPage(1);
    handleSearchChange(_searchkeyword, "");
  };

  const viewPageLink = (id, type, name, isStripeConnected, isSeller) =>
    !!id ? (
      <>
        <NavLink
          target="_blank"
          to={`/${type}/${id}`}
          className="link"
          title={name}
        >
          <span className="name-text" title={name}>
            {name}
          </span>
        </NavLink>
        <br />
        {isStripeConnected && isSeller ? (
          <span className="success-color">Connected</span>
        ) : (
          isSeller && "-"
        )}
      </>
    ) : null;

  const formatName = (name) =>
    name ? name.replace("_", " ").charAt(0).toUpperCase() + name.slice(1) : "-";

  const showActionButton = (status, id) => {
    let buttonText = "";

    if (status === vehicleStatus.SELLER_NO_ACTION.id) {
      buttonText = "Perform Seller Action";
    } else if (status === vehicleStatus.BUYER_NO_ACTION.id) {
      buttonText = "Perform Buyer Action";
    } else {
      return "-";
    }

    return (
      <button onClick={() => validatePayment(status, id)} className="accept">
        {buttonText}
      </button>
    );
  };

  //downloading invoice for vehicle and commission
  const getVehicleInvoice = (url) => {
    // Show loading toast
    const toastId = toast.loading("Preparing your download...", {
      position: toast.POSITION.TOP_RIGHT,
    });
    API.get(url, { responseType: "blob" })
      .then((res) => {
        const resp = res.data;
        if (resp !== null) {
          const content = res.headers["content-type"];
          download(resp, `invoice.pdf`, content);
          // Update loading toast to success
          toast.update(toastId, {
            render: "Download started. Please wait...",
            type: "success",
            isLoading: false,
            autoClose: 5000,
          });
        } else {
          // Update loading toast to error
          toast.update(toastId, {
            render: resp.message,
            type: "error",
            isLoading: false,
            autoClose: 5000,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        if (error.response) {
          // Update loading toast to error
          toast.update(toastId, {
            render: "Something went wrong",
            type: "error",
            isLoading: false,
            autoClose: 5000,
          });
        }
      });
  };

  return (
    <>
      <DepositHistoryWrapper>
        <div className="auctionMainContainer">
          <div className="row mt-4">
            <div className="col-md-4 offset-md-4">
              <h1>VEHICLE RESERVATION HISTORY</h1>
            </div>
          </div>
          <div className="row">
            <div className="justify-content-end mb-2 mt-4 d-flex gap-2 mb-2 flex-wrap align-items-center">
              <SellerCustomersFilter
                _filters={_filters}
                filters={filters}
                setSeller={(val) => setSellerValue(val)}
                seller={sellerID}
                searchSellerKeyword={searchSellerKeyword}
                _searchSellerKeyword={_searchSellerKeyword}
                type={"seller"}
              />
              <BuyerCustomersFilter
                _filters={_filters}
                filters={filters}
                setBuyer={(val) => setBuyerValue(val)}
                buyer={buyerID}
                searchBuyerKeyword={searchBuyerKeyword}
                _searchBuyerKeyword={_searchBuyerKeyword}
                type={"buyer"}
              />
              <div className="d-flex date-range-container gap-2">
                <input
                  type="text"
                  className="date date-range-container"
                  id="start_date"
                  placeholder="Start Date"
                  onFocus={() =>
                    (document.getElementById("start_date").type = "date")
                  }
                  onBlur={() =>
                    dateRange.start === ""
                      ? (document.getElementById("start_date").type = "text")
                      : ""
                  }
                  value={dateRange.start}
                  max={dateRange.end ? dateRange.end : currDate}
                  onChange={(e) => {
                    setDateRange((prev) => ({
                      ...prev,
                      start: e.target.value,
                    }));
                    setPage(1);
                    _totalPage(0);
                  }}
                />
                <input
                  type="text"
                  className="date date-range-container"
                  id="end_date"
                  placeholder="End Date"
                  onFocus={() =>
                    (document.getElementById("end_date").type = "date")
                  }
                  onBlur={() =>
                    dateRange.end === ""
                      ? (document.getElementById("end_date").type = "text")
                      : ""
                  }
                  value={dateRange.end}
                  max={currDate}
                  onChange={(e) => {
                    setDateRange((prev) => ({ ...prev, end: e.target.value }));
                    setPage(1);
                    _totalPage(0);
                  }}
                />
              </div>
              <StaticFilter
                placeholder="Payment Status"
                data={paymentStatusData}
                handleChange={(e) => {
                  _selectedPaymentStatus(e.target.value);
                  _totalPage(0);
                  setPage(1);
                }}
                value={selectedPaymentStatus}
              />
              <StaticFilter
                placeholder="Vehicle Status"
                data={vehicleStatusData}
                handleChange={(e) => {
                  _selectedVehicleStatus(e.target.value);
                  _totalPage(0);
                  setPage(1);
                }}
                value={selectedVehicleStatus}
              />
              <StaticFilter
                placeholder="Pending action"
                data={pendingActionData}
                handleChange={(e) => {
                  _selectedPendingAction(e.target.value);
                  _totalPage(0);
                  setPage(1);
                }}
                value={selectedPendingAction}
              />
              <SearchKey
                onSearchClick={(search) =>
                  setChangeValue(_searchkeyword, search)
                }
                val={searchkeyword}
                id="searchData"
                filters={filters}
                _filters={_filters}
                placeholder="Search by VIN or Vehicle title"
              />
              {/* <CountryFilter value={country} handleChange={(val) => setChangeValue(setCountry, val)} /> */}
              <RecordCount
                onSearchClick={(search) =>
                  handleSearchChange(_countValue, search)
                }
                val={countValue}
                id="pageCount"
                filters={filters}
                _filters={_filters}
              />
              <ExtractReport
                downloadFunction={handleExtractReport}
                styles="extract-report ml-2"
                text=" Extract report"
              />
              <Tooltip title="Clear Filter" placement="bottom">
                <FaFilterIcon
                  className="filter-icon"
                  role="button"
                  onClick={clearFilter}
                />
              </Tooltip>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between info-wrap">
            <div className="justify-content-end mt-1 me-2">
              <span className="text-start">
                Total Records : <b>{total}</b>
              </span>
            </div>
          </div>
          <div className="tableContainer">
            <table className="auctionTable">
              <thead className="tableHead">
                <tr className="table-row">
                  <th className="time-start">Ad ID</th>
                  <th className="time-start">
                    Brand
                    <br />
                    Model
                  </th>
                  <th className="time-start">Vehicle title</th>
                  <th className="time-start">VIN</th>
                  <th className="time-start">
                    Reserved on
                    <br />
                    {/* <Sorting sortType='totalAcquired' sort={sort} handleSort={setsorting} /> */}
                  </th>
                  <th className="time-start">
                    Seller name
                    <br />
                    Bank Account Status
                  </th>
                  <th className="time-start">Buyer name</th>
                  <th className="time-start">
                    Vehicle hotdeal price <br />
                    VAT % <br />
                    hotdeal price + VAT
                  </th>
                  <th className="time-start">Vehicle Reservation Amount</th>
                  <th className="time-start">
                    Cartobike commission <br />
                    VAT % <br />
                    Commission + VAT
                  </th>
                  <th className="time-start">Seller Amount</th>
                  <th className="time-start">Payment status</th>
                  <th className="time-start">Vehicle status</th>
                  <th className="time-start">Refund Reason</th>
                  <th className="time-start">Refund Amount</th>
                  <th className="time-start">Stripe Refund ID</th>
                  <th className="time-start">Action</th>
                </tr>
              </thead>
              {loaded ? (
                <div className="d-flex justify-content-center three-dot">
                  <ThreeDots
                    height="150"
                    width="150"
                    radius="9"
                    color="#637df4"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <tbody>
                  {data?.length > 0 ? (
                    data.map((item, index) => {
                      const paymentStatusLabel =
                        item.payment_status != null
                          ? Object.values(paymentStatus).find(
                              (status) => status.id === item.payment_status
                            )?.label
                          : "-";
                      const vehicleStatusLabel =
                        item.vehicle_status != null
                          ? Object.values(vehicleStatus).find(
                              (status) => status.id === item.vehicle_status
                            )?.label
                          : "-";
                      return (
                        <tr
                          className={
                            (item.deleted_at ? "deletedRow" : "") + " table-row"
                          }
                          key={index}
                        >
                          <td className="time-start">
                            <span className="name-text">
                              {item.ad_vehicle.id || "-"}
                            </span>
                          </td>
                          <td className="time-start">
                            {item.ad_vehicle.owner_vehicle.model.brand_car
                              .name || "-"}
                            <br />
                            {item.ad_vehicle.owner_vehicle.model.model_name ||
                              "-"}
                          </td>
                          <td className="time-start">
                            <a
                              href={`${process.env.REACT_APP_FRONTOFFICE_URL_HTTPS}/en/hot-deal/${item.ad_vehicle?.id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={"link"}
                            >
                              {item.ad_vehicle?.owner_vehicle.full_title}
                            </a>
                          </td>
                          <td className="time-start">
                            {item.ad_vehicle.owner_vehicle.vin || "-"}
                          </td>
                          <td className="time-start">
                            {moment(item.created_at)
                              .tz("Europe/Amsterdam")
                              .format("DD-MM-YYYY HH:mm")}
                          </td>
                          <td className="time-start">
                            <div className="d-flex flex-column">
                              {viewPageLink(
                                item.ad_vehicle.owner_vehicle.current_user?.id,
                                "detail-member",
                                formatName(
                                  item.ad_vehicle.owner_vehicle.current_user
                                    ?.name
                                ),
                                item.ad_vehicle.owner_vehicle?.current_user
                                  ?.stripe_id,
                                "seller"
                              )}
                              {viewPageLink(
                                item.ad_vehicle.owner_vehicle.current_sub?.id,
                                "company-details",
                                formatName(
                                  item.ad_vehicle.owner_vehicle.current_sub
                                    ?.name
                                ),
                                item.ad_vehicle.owner_vehicle?.current_sub
                                  ?.stripe_id,
                                "seller"
                              )}
                            </div>
                          </td>
                          <td className="time-start">
                            <div className="d-flex flex-column">
                              {viewPageLink(
                                item.user?.id,
                                "detail-member",
                                formatName(item.user?.name),
                                item?.user?.stripe_id
                              )}
                              {viewPageLink(
                                item.subsidiary?.id,
                                "company-details",
                                formatName(item.subsidiary?.name),
                                item?.subsidiary?.stripe_id
                              )}
                            </div>
                          </td>
                          <td className="time-start">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div style={{ cursor: "pointer" }}>
                                <Tooltip
                                  title="Vehicle Invoice"
                                  placement="right-end"
                                >
                                  <img
                                    className={
                                      item.subscriptipn_invoice_url !== null
                                        ? "attachment"
                                        : "disabled"
                                    }
                                    src={attach}
                                    alt=""
                                    onClick={() =>
                                      getVehicleInvoice(
                                        item.vehicle_invoice_url
                                      )
                                    }
                                  />
                                </Tooltip>
                              </div>
                              <div>
                                €{" "}
                                {(item.ad_vehicle.cartobike_total_price
                                  ? item.ad_vehicle.cartobike_price
                                  : item.ad_vehicle.discounted_price) || "-"}
                                <br />
                                {item.ad_vehicle.vehicle_deductible_vat == 1
                                  ? (item?.ad_vehicle?.owner_vehicle
                                      ?.current_sub?.limited_address
                                      ?.vat_rate ||
                                      item?.ad_vehicle?.owner_vehicle
                                        ?.current_user?.limited_address
                                        ?.vat_rate) + " %"
                                  : "-"}
                                <br />€{" "}
                                {item.ad_vehicle.cartobike_total_price ||
                                  item.ad_vehicle.total_discounted_price ||
                                  "-"}
                              </div>
                            </div>
                          </td>
                          <td className="time-start">€ {item.total || "-"}</td>
                          <td className="time-start">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {item.commission_invoice_url && (
                                <div style={{ cursor: "pointer" }}>
                                  <Tooltip
                                    title="Commission Invoice"
                                    placement="right-end"
                                  >
                                    <img
                                      className={
                                        item.subscriptipn_invoice_url !== null
                                          ? "attachment"
                                          : "disabled"
                                      }
                                      src={attach}
                                      alt=""
                                      onClick={() =>
                                        getVehicleInvoice(
                                          item.commission_invoice_url
                                        )
                                      }
                                    />
                                  </Tooltip>
                                </div>
                              )}
                              <div>
                                € {item.commission_amount / 100 || "-"}
                                <br />
                                {item.commission_tax_amount / 100
                                  ? taxRate + " %"
                                  : "-"}
                                <br />€{" "}
                                {(item.commission_amount +
                                  item.commission_tax_amount) /
                                  100 || "-"}
                              </div>
                            </div>
                          </td>
                          <td className="time-start">
                            {item.seller_amount
                              ? "€ " + item.seller_amount / 100
                              : "-"}
                          </td>
                          <td className="time-start">{paymentStatusLabel}</td>
                          <td className="time-start">{vehicleStatusLabel}</td>
                          <td className="time-start">{item.reason ?? "-"}</td>
                          <td className="time-start">
                            {item.refund_amount
                              ? "€ " + item.refund_amount
                              : "-"}
                          </td>
                          <td className="time-start">
                            {item.stripe_refund_id ?? "-"}
                          </td>
                          <td className="time-start">
                            {showActionButton(
                              item.vehicle_status,
                              item.ad_vehicle.id
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <NoRecord />
                  )}
                </tbody>
              )}
              {/* total records */}
              {!loaded ? (
                <tr className="table-row summary-row">
                  <td className="time-start maxw-90"></td>
                  <td className="time-start name-text"></td>
                  <td className="time-start maxw-90"></td>
                  <td className="time-start"></td>
                  <td className="time-start"></td>
                  <td className="time-start"></td>
                  <td className="time-start"></td>
                  <td className="time-start"></td>
                  <td className="time-start">
                    <CurrencyDisplay
                      amount={totalAmountData?.totalVehicleReservationAmount}
                      title="Total Commission amount"
                    />
                  </td>
                  <td className="time-start">
                    <div className="d-flex flex-column">
                      <CurrencyDisplay
                        amount={totalAmountData?.totalCommissionAmount}
                        title="Total Commission amount"
                      />
                      <CurrencyDisplay
                        amount={totalAmountData?.totalVatAmount}
                        title="Total VAT amount"
                      />
                      <hr />
                      <CurrencyDisplay
                        amount={totalAmountData?.finalTotalCommissionAmount}
                        title="Total Commission with VAT Amount"
                      />
                    </div>
                  </td>
                  <td className="time-start">
                    <CurrencyDisplay
                      amount={totalAmountData?.finalTotalSellerAmount}
                      title="Total Commission amount"
                    />
                  </td>
                  <td className="time-start"></td>
                  <td className="time-start"></td>
                  <td className="time-start"></td>
                </tr>
              ) : null}
            </table>
          </div>
          <div>
            {totalPage > 1 ? (
              <Pagination
                totalPages={totalPage}
                page={page}
                key={totalPage}
                onPageClick={(page) => setPage(page + 1)}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </DepositHistoryWrapper>
    </>
  );
};

export default DepositHistory;
