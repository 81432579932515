import { Button, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import SearchKey from "../../../../assets/SharedComponents/Search/SearchKey";
import attach from "../../../../assets/Images/icons/svgIcon/Attach.svg";
import { FaFilterIcon } from "../../../../assets/Images/icon";
import Header from "./Header";
import API from "../../../../api/axios";
import { APP_URLS } from "../../../../api/url";
import { toast } from "react-toastify";
import RecordCount from "../../../../assets/SharedComponents/RecordCount/RecordCount";
import NoRecord from "../../../../assets/SharedComponents/Table/NoRecord";
import Pagination from "../../../../assets/SharedComponents/Pagination";
import moment from "moment";
import * as Constant from "../../../../config/constant";
import download from "downloadjs";
import { getSum } from "../../../../core/utils/getSum/getSum";
import StaticFilter from "../../../../assets/SharedComponents/Filters/StaticFilter";
import { useLocation, useSearchParams } from "react-router-dom";
import CountryFilter from "../../../../assets/SharedComponents/Filters/CountryFilter";
import {
  constructQueryStringFromIds,
  downloadFileError,
  formatCurrency,
} from "../../../../core/utils";
import ExtractReport from "../../../../assets/SharedComponents/ExtractReport/ExtractReport";

const invoiceStatusOptions = {
  Paid: "paid",
  Open: "open",
  Void: "void",
};
const invoiceStatusOptionsList = Object.keys(invoiceStatusOptions).map(
  (key) => ({ name: key, value: invoiceStatusOptions[key] })
);

const CreditTable = ({ tabView, setTabView, showFilters, _action, action }) => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const [searchParams, setSearchParams] = useSearchParams();
  const day = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    .toJSON()
    .slice(0, 10);

  const [data, _data] = useState([]);
  const [calData, _calData] = useState("");
  const [count, _count] = useState(0);
  const [searchkeyword, _searchkeyword] = useState("");
  const [searchInvoicekeyword, _searchInvoicekeyword] = useState("");
  const [status, _status] = useState("");
  const [trashed, _trashed] = useState(true);
  const [vat, _vat] = useState("");
  const [filters, _filters] = useState(false);
  const [loaded, _loaded] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, _totalPage] = useState(0);
  const [countValue, _countValue] = useState("");

  //SideBarFilters
  const [country, _country] = useState("");
  const [dateRange, setDateRange] = useState({
    start: "",
    end: "",
  });
  const [seller, setSeller] = useState(
    searchParams.get("a_seller_allowed") !== null
      ? searchParams.get("a_seller_allowed")
      : 0
  );
  const userTypesList = Object.keys(Constant.auctionUserTypes).map((key) => ({
    name: key,
    value: Constant.auctionUserTypes[key],
  }));
  const [selectedIds, setSelectedIds] = useState([]);
  const [allInvoices, _allInvoices] = useState(false);

  useEffect(() => {
    getSubscriptions();
  }, [
    page,
    countValue,
    searchkeyword,
    status,
    dateRange,
    country,
    searchInvoicekeyword,
    trashed,
    vat,
    seller,
  ]);

  function getRestUrl() {
    let urlParams = {};

    urlParams["filter[type]"] = Constant.PAYMENT_TYPE.CREDIT_BUY;
    if (page) urlParams["page"] = page;
    if (countValue) urlParams["per_page"] = countValue;
    if (searchkeyword) urlParams["keyword"] = searchkeyword;
    if (seller !== 0) urlParams["profile"] = seller;
    if (status) urlParams["filter[status]"] = status;
    if (dateRange && dateRange.start)
      urlParams["filter[subscription_invoice_plan_start_date]"] =
        dateRange.start;
    if (dateRange && dateRange.end)
      urlParams["filter[subscription_invoice_plan_end_date]"] = dateRange.end;
    if (country) urlParams["filter[country_id]"] = country;
    if (vat) urlParams["filter[vat_number]"] = vat;
    if (searchInvoicekeyword)
      urlParams["filter[stripe_invoice_number]"] = searchInvoicekeyword;
    if (trashed !== undefined) urlParams["with_trashed"] = trashed ? 1 : 0;

    const restUrl = new URLSearchParams(urlParams);
    return restUrl.toString();
  }

  //fetching invoice listing for credits
  const getSubscriptions = () => {
    const apiParams = getRestUrl();
    _loaded(true);
    API.get(`${APP_URLS.LIST_SUBSCRIPTION_INVOICE}?${apiParams}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.subscriptionInvoices.data);
          _totalPage(resp.data.subscriptionInvoices.last_page);
          _calData(resp.data.totalAmount);
          _count(resp.data.subscriptionInvoices.total);
          _loaded(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loaded(false);
        }
      })
      .catch(function (error) {
        _loaded(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map(
              (error, index) => (error_message = resp.data.errors[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  //downloading invoice for credit
  const getAttachment = (url) => {
    API.get(url, { responseType: "blob" })
      .then((res) => {
        const resp = res.data;
        if (resp !== null) {
          toast.success("Downloading. Please wait", {
            position: toast.POSITION.TOP_RIGHT,
          });
          const content = res.headers["content-type"];
          download(resp, `invoice.pdf`, content);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        if (error.response) {
          toast.error("Something went wrong", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  //handles common search functionality for search by name, invoice and per_page
  const handleSearchChange = (func, searchValue) => {
    func(searchValue);
    if (searchValue !== "") {
      _totalPage(0);
      setPage(1);
    }
  };

  //reset filter
  const clearFilter = () => {
    _filters(true);
    _status("");
    _vat("");
    setSeller(0);
    _country("");
    _trashed(true);
    handleSearchChange(_countValue, "");
    handleSearchChange(_searchkeyword, "");
    handleSearchChange(_searchInvoicekeyword, "");
    setDateRange({
      start: "",
      end: "",
    });
    if (
      searchkeyword !== "" ||
      status !== "" ||
      vat !== "" ||
      searchInvoicekeyword !== "" ||
      dateRange.start !== "" ||
      dateRange.end !== "" ||
      trashed !== true
    ) {
      _totalPage(0);
      setPage(1);
    }
    for (var value of query.keys()) {
      const param = searchParams.get(value);
      if (param) {
        searchParams.delete(value);
        setSearchParams(searchParams);
      }
    }
  };

  //getting flag url according to user type
  function getFlagURL(data) {
    if (
      data.subsidiary &&
      data.subsidiary.limited_address &&
      data.subsidiary.limited_address.flag_thumbnail
    ) {
      return data.subsidiary.limited_address.flag_thumbnail;
    } else if (
      data.user &&
      data.user.limited_address &&
      data.user.limited_address.flag_thumbnail
    ) {
      return data.user.limited_address.flag_thumbnail;
    }
    return null; // return null or a default value if flag URL is not found
  }

  //capitalize function for names
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  //geeting names according to user type
  function getName(data) {
    if (data.subsidiary && data.subsidiary.name) {
      return capitalizeFirstLetter(data.subsidiary.name);
    } else if (data.user && (data.user.first_name || data.user.last_name)) {
      const fullName = `${data.user.first_name} ${data.user.last_name}`.trim();
      return capitalizeFirstLetter(fullName);
    } else if (data.user && data.user.name) {
      return capitalizeFirstLetter(data.user.name);
    }
    return "-";
  }

  //seller filter
  const getseller = (item) => {
    setSeller(item);
    _totalPage(0);
    setPage(1);
  };

  //country filter
  const setChangeValue = (item) => {
    _country(item);
    _totalPage(0);
    setPage(1);
  };

  //to download excel extract report for this page
  const handleExtractReport = () => {
    const apiurl = getRestUrl();
    API.get(`${APP_URLS.CREDIT_EXTRACT_REPORT}/download_excel_file?${apiurl}`, {
      responseType: "blob",
    })
      .then((res) => {
        const resp = res.data;
        if (resp !== null) {
          toast.success("Downloading. Please wait", {
            position: toast.POSITION.TOP_RIGHT,
          });
          const content = res.headers["content-type"];
          const currentDate = moment().format("YYYY-MM-DD HH-mm-ss");
          const fileName = `${currentDate}_credit_invoices_report.xlsx`;
          download(resp, fileName, content);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(async function (error) {
        let error_message = await downloadFileError(error);
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  //to download zip file for invoices
  const handleDownloadInvoiceZip = () => {
    if (selectedIds.length === 0) {
      toast.error("Please select at least one invoice to download.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    //constructing params for multiple invoices based on id's
    const selectedIdsParams = constructQueryStringFromIds(selectedIds, "id");

    API.get(
      `${APP_URLS.LIST_SUBSCRIPTION_INVOICE}/download_zip_file?type=${Constant.PAYMENT_TYPE.CREDIT_BUY}&${selectedIdsParams}`
    )
      .then((res) => {
        const resp = res.data;
        if (resp.success) {
          toast.success(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map(
              (error, index) => (error_message = resp.data.errors[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message || "Something went wrong";
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  //handles particular selecting checkbox for invoice download in zip format
  const handleCheckboxChange = (id) => {
    setSelectedIds((prevIds) =>
      prevIds.includes(id)
        ? prevIds.filter((item) => item !== id)
        : [...prevIds, id]
    );
  };

  //handles selecting all checkbox for invoice download in zip format
  const handleSelectAll = () => {
    if (allInvoices) {
      setSelectedIds([]);
    } else {
      setSelectedIds(data.map((item) => item.id));
    }
    _allInvoices(!allInvoices);
  };

  return (
    <div className="auctionMainContainer">
      <div className="row mt-4 position-relative">
        <div
          className={
            showFilters === true ? "col-md-9 col-lg-12" : "col-md-12 col-lg-12"
          }
        >
          <Header
            tabView={tabView}
            setTabView={setTabView}
            action={action}
            _action={_action}
            handleDownloadInvoiceZip={handleDownloadInvoiceZip}
          />
          <div className="col-md-12 mt-2">
            <div className="d-flex align-items-center justify-content-end flex-wrap">
              <div className="justify-content-end mb-2 d-flex gap-2 mb-2 flex-wrap align-items-center">
                <div className="d-flex date-range-container gap-2">
                  <input
                    type="text"
                    className="date date-range-container"
                    id="start_date"
                    placeholder="Start Date"
                    onFocus={() =>
                      (document.getElementById("start_date").type = "date")
                    }
                    onBlur={() =>
                      dateRange.start === ""
                        ? (document.getElementById("start_date").type = "text")
                        : ""
                    }
                    value={dateRange.start}
                    max={dateRange.end ? dateRange.end : day}
                    onChange={(e) => {
                      setDateRange((prev) => ({
                        ...prev,
                        start: e.target.value,
                      }));
                      setPage(1);
                      _totalPage(0);
                    }}
                  />
                  <input
                    type="text"
                    className="date date-range-container"
                    id="end_date"
                    placeholder="End Date"
                    onFocus={() =>
                      (document.getElementById("end_date").type = "date")
                    }
                    onBlur={() =>
                      dateRange.end === ""
                        ? (document.getElementById("end_date").type = "text")
                        : ""
                    }
                    value={dateRange.end}
                    max={day}
                    onChange={(e) => {
                      setDateRange((prev) => ({
                        ...prev,
                        end: e.target.value,
                      }));
                      setPage(1);
                      _totalPage(0);
                    }}
                  />
                </div>
                <div style={{ width: "250px" }}>
                  <CountryFilter
                    value={country}
                    handleChange={(val) => setChangeValue(val)}
                  />
                </div>
                <StaticFilter
                  placeholder="Seller"
                  data={userTypesList}
                  handleChange={(e) => {
                    getseller(e.target.value);
                  }}
                  value={seller}
                />
                <StaticFilter
                  placeholder="Invoice Status"
                  data={invoiceStatusOptionsList}
                  handleChange={(e) => {
                    _status(e.target.value);
                    _totalPage(0);
                    setPage(1);
                  }}
                  value={status}
                />
                <SearchKey
                  onSearchClick={(search) =>
                    handleSearchChange(_searchInvoicekeyword, search)
                  }
                  id="searchData"
                  filters={filters}
                  _filters={_filters}
                  placeholder="Search by Invoice Number"
                />
                <SearchKey
                  onSearchClick={(search) =>
                    handleSearchChange(_searchkeyword, search)
                  }
                  id="searchData"
                  filters={filters}
                  _filters={_filters}
                  placeholder={`Search by Name`}
                />
                <RecordCount
                  onSearchClick={(search) =>
                    handleSearchChange(_countValue, search)
                  }
                  id="pageCount"
                  filters={filters}
                  _filters={_filters}
                />
                <ExtractReport
                  downloadFunction={handleExtractReport}
                  styles="extract-report ml-2"
                  text=" Extract report"
                />
                <div className="d-flex align-items-center">
                  <Tooltip title="Clear Filter" placement="bottom">
                    <FaFilterIcon
                      className="filter-icon"
                      role="button"
                      onClick={clearFilter}
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between info-wrap">
              <div className="justify-content-end mt-1 me-2">
                <span className="text-start">
                  Total Records : <b>{count}</b>
                </span>
              </div>
              <div className="d-flex gap-3">
                {action && (
                  <div className="mt-1">
                    <input
                      type="checkbox"
                      checked={allInvoices}
                      indeterminate={
                        selectedIds.length > 0 &&
                        selectedIds.length < data.length
                      }
                      onChange={handleSelectAll}
                    />
                    <span className="ms-2">Select all invoices</span>
                  </div>
                )}
                <div className="mt-1">
                  <input
                    type="checkbox"
                    checked={!trashed}
                    onChange={() => {
                      _totalPage(0);
                      setPage(1);
                      _trashed(!trashed);
                    }}
                  />
                  <span className="ms-2">Hide Deleted Data</span>
                </div>
              </div>
            </div>
            <div className="tableContainer">
              <table className="auctionTable">
                <thead className="tableHead">
                  <tr className="table-row">
                    <th className="maxw-90">
                      ID
                      <br />
                      Country
                    </th>
                    <th className="time-start name-text">
                      Name <br />
                      Invoice Number
                    </th>
                    <th>Invoice Status</th>
                    <th className="time-start">
                      Plan Name
                      <br />
                    </th>
                    <th>
                      <span className="fieldTitle">(Invoice)</span>
                      <br />
                      Plan Amount <br />
                      <span className="fieldTitle">(Before Discount)</span>
                      <br />
                      Date
                    </th>
                    <th>Discount Amount</th>
                    <th>Total Excluding VAT Amount</th>
                    <th>
                      <span className="fieldTitle">(Invoice)</span>
                      <br />
                      Amount <br />
                      <span className="fieldTitle">(After Discount)</span>
                      <br />
                      VAT %
                    </th>
                    <th>Stripe Fee</th>
                    <th>Balance</th>
                  </tr>
                </thead>
                {loaded ? (
                  <div className="d-flex justify-content-center three-dot">
                    <ThreeDots
                      height="150"
                      width="150"
                      radius="9"
                      color="#637df4"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                ) : (
                  <tbody>
                    {data.length > 0 ? (
                      data.map((item) => {
                        return (
                          <tr
                            className={
                              (item.deleted_at ? "deletedRow" : "") +
                              " table-row"
                            }
                            key={item.id}
                          >
                            <td className="maxw-90">
                              <span className="d-block">{item.id} </span>
                              <span>
                                <img
                                  src={getFlagURL(item)}
                                  alt=""
                                  className="countryflag"
                                  title={
                                    item?.subsidiary?.limited_address
                                      ?.country ??
                                    item?.user?.limited_address?.country
                                  }
                                />
                              </span>
                            </td>
                            <td className="time-start name-text">
                              <span className="d-block" title={getName(item)}>
                                {getName(item)}
                              </span>
                              <span>
                                {item.number !== null ? item.number : ""}
                              </span>
                            </td>
                            <td>
                              <span
                                className={Constant.INVOICE_STATUS[item.status]}
                              >
                                {item.status.charAt(0).toUpperCase() +
                                  item.status.slice(1)}
                              </span>
                            </td>
                            <td className="time-start">
                              {item.latest_invoice_item !== null ? (
                                <>
                                  <span className="d-block">
                                    {item.latest_invoice_item.price !== null &&
                                      item.latest_invoice_item.price.plan.name}
                                  </span>
                                </>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>
                              <div className="d-flex justify-content-evenly">
                                <div className="mw-120">
                                  <span
                                    className="block"
                                    title="Amount Without VAT"
                                  >
                                    {formatCurrency(item.subtotal)}
                                  </span>
                                  <span className="d-block text-nowrap">
                                    {moment(item.invoice_date)
                                      .tz("Europe/Amsterdam")
                                      .format("DD-MM-YYYY HH:mm")}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              {item.discount_amount ? (
                                <span className="end-status">
                                  {formatCurrency(item.discount_amount)}
                                </span>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>
                              {item.total_excluding_vat ? (
                                <span>
                                  {formatCurrency(item.total_excluding_vat)}
                                </span>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>
                              <div className="d-flex justify-content-evenly">
                                {action ? (
                                  <input
                                    type="checkbox"
                                    checked={selectedIds.includes(item.id)}
                                    onChange={() =>
                                      handleCheckboxChange(item.id)
                                    }
                                  />
                                ) : null}
                                <Tooltip
                                  title="Credit Invoice"
                                  placement="right-end"
                                >
                                  <img
                                    className={
                                      item.subscriptipn_invoice_url !== null
                                        ? "attachment"
                                        : "disabled"
                                    }
                                    src={attach}
                                    alt=""
                                    onClick={() =>
                                      getAttachment(
                                        item.subscriptipn_invoice_url
                                      )
                                    }
                                  />
                                </Tooltip>
                                <div className="mw-120">
                                  <span
                                    className="block"
                                    title="Amount Without VAT"
                                  >
                                    {formatCurrency(item.amountAfterDiscount)}
                                  </span>
                                  <p className="d-block">
                                    {item.subscription_invoice_vat} %
                                    <span className="light-text">
                                      (
                                      {item.tax !== null
                                        ? formatCurrency(item.tax)
                                        : "-"}
                                      )
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <span className="end-status">
                                {formatCurrency(item.stripe_fee)}
                              </span>
                            </td>
                            <td className="balance active-status">
                              {formatCurrency(item.balance)}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <NoRecord />
                    )}
                    <tr className="table-row summary-row">
                      <td className="maxw-90"></td>
                      <td className="time-start name-text"></td>
                      <td></td>
                      <td></td>
                      <td className="time-start">
                        <b title="Total Amount Before Discount">
                          {calData !== ""
                            ? formatCurrency(calData.totalAmount)
                            : "-"}
                        </b>
                      </td>
                      <td>
                        <b title="Total Discount Amount">
                          {calData !== ""
                            ? formatCurrency(calData.totalDiscountAmount)
                            : "-"}
                        </b>
                      </td>
                      <td>
                        <b title="Total Excludig VAT Amount">
                          {calData !== ""
                            ? formatCurrency(calData.totalExcludingVat)
                            : "-"}
                        </b>
                      </td>
                      <td>
                        <div className="d-flex flex-column">
                          <b title="Total Amount After Discount">
                            {calData !== ""
                              ? formatCurrency(calData.totalAmountAfterDiscount)
                              : "-"}
                          </b>
                          <b title="Total VAT">
                            {calData !== ""
                              ? formatCurrency(
                                  calData.totalSubscriptionVatAmount
                                )
                              : "-"}
                          </b>
                          <hr />
                          <span
                            className="fieldTitle"
                            title="Total Amount with VAT"
                          >
                            {calData !== ""
                              ? "€ " +
                                getSum(
                                  calData.totalAmountAfterDiscount,
                                  calData.totalSubscriptionVatAmount
                                )
                              : "-"}
                          </span>
                        </div>
                      </td>
                      <td>
                        <b>
                          {calData !== ""
                            ? formatCurrency(calData.totalStripeFee)
                            : "-"}
                        </b>
                      </td>
                      <td>
                        <div className="d-flex flex-column">
                          <b title="Total Balance Amount">
                            {calData !== ""
                              ? formatCurrency(calData.totalBalance)
                              : "-"}
                          </b>
                          <b title="Total Balance VAT">
                            {calData !== ""
                              ? formatCurrency(calData.totalVatBalance)
                              : "-"}
                          </b>
                          <hr />
                          <span
                            className="fieldTitle"
                            title="Total Balance Amount with VAT"
                          >
                            {calData !== ""
                              ? "€ " +
                                getSum(
                                  calData.totalBalance,
                                  calData.totalVatBalance
                                )
                              : "-"}
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
            <div>
              {totalPage > 1 ? (
                <Pagination
                  totalPages={totalPage}
                  page={page}
                  onPageClick={(page) => {
                    setPage(page + 1);
                  }}
                  key={totalPage}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditTable;
